/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {useCallInterval} from '../../../../hooks/CallTimeIntervalContext'
import {AuthContext} from '../../../../hooks/authcontext'

type Props = {
  className: string
  employeeQuery?: string
  isCompact?: boolean
  title?: string
  setSelectedIndex?: any
}

type Employee = {
  name: string
  totalScore: number
  technicalScore: number
  potentialScore: number
  appraisalScore: number
  lmScore: number
  potentialRatio: number
  technicalRatio: number
  appraisalRatio: number
  lmRatio: number
  'employee id': string
}

const EmpChart: React.FC<Props> = ({className, employeeQuery, isCompact = false, title}) => {
  const [barHeight, setBarHeight] = useState(600)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [chartData, setChartData] = useState<Array<{name: string; data: number[]; color: string}>>(
    []
  )
  const {mode} = useThemeMode()
  const [empData, setEmpData] = useState<Employee>()
  const {userId} = useContext(AuthContext)

  const {callInterval} = useCallInterval()

  const [originalValues, setOriginalValues] = useState<number[]>([])
  const [chartBase64, setChartBase64] = useState('')

  const threshold = 1

  // Helper function to clean up color values
  function cleanColor(color: any) {
    // remove any leading or trailing whitespace
    color = color.trim()
    // remove any leading # characters
    color = color.replace(/^#+/, '')
    // ensure the color string is exactly 6 characters long
    if (color.length < 6) {
      color = color.padStart(6, '0')
    } else if (color.length > 6) {
      color = color.slice(0, 6)
    }
    // add a leading # character
    color = '#' + color
    return color
  }

  async function callEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEmployeeData',
            employee_id: employeeQuery,
          }),
        }
      )

      const allEmpDataCont = await allEmpData.json()

      const dummyData: Employee = {
        name: allEmpDataCont.employee.name,

        totalScore: allEmpDataCont.employee['employee score'],
        technicalScore: allEmpDataCont.employee['performance technical score'],
        potentialScore: allEmpDataCont.employee['potential score'],
        appraisalScore: allEmpDataCont.employee['performance appraisal score'],
        lmScore: allEmpDataCont.employee['line manager score'],
        'employee id': allEmpDataCont.employee['employee id'],
        potentialRatio: allEmpDataCont['employee ratios']['potential skill ratio'] * 100,
        appraisalRatio: allEmpDataCont['employee ratios']['appraisal skill ratio'] * 100,
        lmRatio: allEmpDataCont['employee ratios']['line manager skill ratio'] * 100,
        technicalRatio: allEmpDataCont['employee ratios']['technical skill ratio'] * 100,
      }

      let potentialColor = cleanColor(
        getComputedStyle(document.documentElement).getPropertyValue('--bs-potential')
      )

      let techColor = cleanColor(
        getComputedStyle(document.documentElement).getPropertyValue('--bs-technical')
      )

      let appraisalColor = cleanColor(
        getComputedStyle(document.documentElement).getPropertyValue('--bs-appraisal')
      )

      let lmColor = cleanColor(
        getComputedStyle(document.documentElement).getPropertyValue('--bs-line-manager')
      )

      const totalScoreChartData: {name: string; data: number[]; color: string}[] = []

      if (dummyData.potentialRatio !== 0) {
        totalScoreChartData.push({
          name: 'Potential',
          data: [Math.round(dummyData.potentialScore * dummyData.potentialRatio)],
          color: potentialColor,
        })
      }

      if (dummyData.technicalRatio !== 0) {
        totalScoreChartData.push({
          name: 'Performance (Technical)',
          data: [Math.round(dummyData.technicalScore * dummyData.technicalRatio)],
          color: techColor,
        })
      }

      if (dummyData.appraisalRatio !== 0) {
        totalScoreChartData.push({
          name: 'Performance (Appraisal)',
          data: [Math.round(dummyData.appraisalScore * dummyData.appraisalRatio)],
          color: appraisalColor,
        })
      }

      if (dummyData.lmRatio !== 0) {
        totalScoreChartData.push({
          name: 'Line Manager',
          data: [Math.round(dummyData.lmScore * dummyData.lmRatio)],
          color: lmColor,
        })
      }

      const originalDataValues: React.SetStateAction<number[]> = []

      // Loop through each data point
      totalScoreChartData.forEach((dataPoint) => {
        originalDataValues.push(dataPoint.data[0]) // Store the original value
        if (dataPoint.data[0] < threshold) {
          dataPoint.data[0] = threshold // Set the value to the threshold if it's less than the threshold
        }
      })

      setChartData(totalScoreChartData)
      setOriginalValues(originalDataValues)

      if (dummyData) setEmpData(dummyData)
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callEmpData(employeeQuery)
    } else {
      callEmpData(userId ? userId : undefined)
    }
  }, [employeeQuery])

  useEffect(() => {
    const chart = refreshChart()
    var barCheck
    if (document.getElementById('chart-container')) {
      barCheck = document.getElementById('chart-container')?.clientHeight
      barCheck = barCheck ? barCheck - 50 : barCheck
    }

    if (barCheck === undefined || barCheck === null || barCheck < 120) {
      barCheck = 120
    }

    setBarHeight(barCheck)
    getbase64chart(chart)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, barHeight, chartData])

  async function getbase64chart(chart: any) {
    const base64 = await chart?.dataURI()
    setChartBase64(base64)
  }
  const refreshChart = () => {
    if (!chartRef.current || chartData === undefined || chartData.length === 0) {
      return
    }

    if (chartData) {
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(barHeight, chartData, originalValues)
      )

      if (chart) {
        chart.render()
      }
      return chart
    }

    const NO_VALUE_MIN_WIDTH = 1 // to display something even when value is falsy
    const MIN_WIDTH = 50
  }

  const handleRefresh = () => {
    if (employeeQuery) {
      callEmpData(employeeQuery)
    } else {
      callEmpData(userId ? userId : undefined)
    }
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        <button className=' btn btn-primary btn-icon ' onClick={handleRefresh}>
          <i className='fa-solid fa-arrows-rotate'></i>
        </button>
      </div>
      {chartData.length !== 0 ? (
        <div
          className='card-body d-flex justify-content-center align-items-center align-content-center h-100 p-0 m-0'
          id='chart-container'
        >
          <div style={{height: '100% ', width: '95%'}} ref={chartRef} id='total-score-chart' />
        </div>
      ) : (
        <WarningSpinner
          spinnerWidth='80px'
          canReload={true}
          reloadFunc={callEmpData}
          reloadFuncProps={employeeQuery ? employeeQuery : userId}
        />
      )}
    </div>
  )
}

export {EmpChart}

function getChartOptions(
  height: number,
  chartData: {name: string; data: number[]; color: string}[],
  originalValues: number[]
): ApexOptions {
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: chartData,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      width: '100%',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        columnWidth: '100%',
        borderRadius: 5,
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (value, {seriesIndex, dataPointIndex}) {
        value = originalValues[seriesIndex]
        return '' + value + ''
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      min: 0,
      max: 100,
      categories: ['Score'],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: 'right',
      horizontalAlign: 'right',
      offsetX: -20,
      offsetY: -20,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, {seriesIndex, dataPointIndex}) {
          val = originalValues[seriesIndex]
          return '' + val + ''
        },
      },
    },

    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}
