import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
// import {ProfileImageProvider} from '../ProfileImageContext'
import {CallTimeProvider} from '../hooks/CallTimeIntervalContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        {/* <ProfileImageProvider> */}
        <CallTimeProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </CallTimeProvider>
        {/* </ProfileImageProvider> */}
      </I18nProvider>
    </Suspense>
  )
}

export {App}
