import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import AdminCreateUserModal from './AdminCreateUserModal'

type Props = {
  className?: string
  size?: 'lg' | 'sm' | 'xl'
  openModal?: any
  setOpenModal?: any
  setUpdate?: any
}

const AdminCreateCandidateModal: FC<Props> = ({size, openModal, setOpenModal, setUpdate}) => {
  const [candidateGender, setCandidateGender] = useState('male')
  const [openUserModal, setOpenUserModal] = useState(false)

  const createNewCandidate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    console.log(formData.get('createUser'))
    console.log(formData.get('createUser') === 'true')
    if (formData.get('createUser') === 'true') {
      console.log('OPENING ANOTHER MODAL')
      setOpenUserModal(true)
      setOpenModal(false)
    }
    // const newUserInfo = await fetch(
    //   process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({}),
    //   }
    // )

    // const newUserInfoCont = await newUserInfo.json()

    setUpdate(true)
  }

  return (
    <>
      <Modal size={size} centered show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Candidate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={createNewCandidate}>
            <div className='d-flex flex-column'>
              <div className='mb-2'>
                <label htmlFor='entity'>Entity:</label>
                <input
                  type='dropdown'
                  className='form-control form-control-lg form-control-solid'
                  name='entity'
                  id='entity'
                  placeholder='TechZone'
                />
              </div>

              <div className='d-flex flex-row mb-2'>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='name'>Name:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    id='name'
                    placeholder='John Doe'
                  />
                </div>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor='email'>Email:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='email'
                    id='email'
                    placeholder='johndoe@company.com'
                  />
                </div>
              </div>

              <div className='d-flex flex-row mb-2'>
                <div className='d-flex flex-column me-5'>
                  <label htmlFor='gender'>Gender:</label>
                  <div className='d-flex'>
                    <Button
                      variant={candidateGender === 'male' ? 'primary' : 'secondary'}
                      className='me-5'
                      onClick={() => setCandidateGender('male')}
                    >
                      Male
                    </Button>
                    <Button
                      variant={candidateGender === 'female' ? 'primary' : 'secondary'}
                      onClick={() => setCandidateGender('female')}
                    >
                      Female
                    </Button>
                  </div>
                </div>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='nationality'>Nationality:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='nationality'
                    id='nationality'
                    placeholder='United States'
                  />
                </div>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor='dob'>Date Of Birth:</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='dob'
                    id='dob'
                  />
                </div>
              </div>

              <div className='mb-2'>
                <label htmlFor='jobTitle'>Job Title:</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='jobTitle'
                  id='jobTitle'
                  placeholder='IT Support'
                  style={{marginBottom: '10px'}}
                />
              </div>

              <div className='d-flex flex-row mb-2'>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='department'>Department:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='department'
                    id='department'
                    placeholder='HR'
                  />
                </div>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='department'>Section:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='department'
                    id='department'
                    placeholder='HR'
                  />
                </div>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='department'>Division:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='department'
                    id='department'
                    placeholder='HR'
                  />
                </div>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor='department'>Sector:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='department'
                    id='department'
                    placeholder='HR'
                  />
                </div>
              </div>

              <div className='d-flex flex-row mb-2'>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='doj'>Date of Joining:</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='doj'
                    id='doj'
                  />
                </div>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor='yearsOfExp'>Years of Experiences:</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='yearsOfExp'
                    id='yearsOfExp'
                    placeholder='70'
                    style={{marginBottom: '10px'}}
                  />
                </div>
              </div>

              <div className='d-flex flex-row mb-2'>
                <div className='d-flex flex-column w-100 me-5'>
                  <label htmlFor='cert-license'>Role Reporting To:</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='cert-license'
                    id='cert-license'
                    placeholder='e.g. TechZone'
                    style={{marginBottom: '10px'}}
                  />
                </div>
                <div className='d-flex flex-column w-100'>
                  <label htmlFor='cert-issue'>Reporting To you:</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='cert-issue'
                    id='cert-issue'
                    placeholder='70'
                    style={{marginBottom: '10px'}}
                  />
                </div>
              </div>

              <div className='d-flex flex-row mb-2'>
                <input
                  type='checkbox'
                  id='createUser'
                  name='createUser'
                  value='true'
                  className='me-2'
                />
                <div>Create a user account for this candidate</div>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button variant='danger' onClick={() => setOpenModal(false)}>
                  Close
                </Button>

                <Button type='submit'>Add</Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <AdminCreateUserModal openModal={openUserModal} setOpenModal={setOpenUserModal} />
    </>
  )
}

export default AdminCreateCandidateModal
