import {FC, useContext, useEffect, useState} from 'react'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {UserModel} from '../../../../dataTypes'
import configJson from '../../../../config.json'
import {Buffer} from 'buffer'
import {useCallInterval} from '../../../../hooks/CallTimeIntervalContext'
import {AuthContext} from '../../../../hooks/authcontext'

const itemClass = 'ms-1 ms-lg-3'

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'
//maybe pass an image prop to the following func instead of calling it twice
const Navbar: FC = () => {
  const {config} = useLayout()
  const [imageProp, setImageProp] = useState('')
  const [errorHappened, setErrorHappened] = useState(false)
  const {callInterval} = useCallInterval()

  const {userType, userId} = useContext(AuthContext)

  async function callImage() {
    if (userId && userType == 'employee') {
      //check if empty then call default
      // if (employeeQuery) {
      // try {
      //   const getImg = await fetch(
      //     configJson.domainName + ':' + configJson.port + '/api/employeequery',
      //     {
      //       method: 'POST',
      //       headers: {'Content-Type': 'application/json'},
      //       body: JSON.stringify({
      //         function: 'GetEmployeePicture',
      //         employee_id: user['user id'],
      //         // employee_id: employeeQuery,
      //       }),
      //     }
      //   )

      //   const getImgCont = await getImg.json()
      //   if (getImg.status == 200) {
      //     const certArray = decodeBase64(getImgCont['message'])
      //     setImageProp(certArray)
      //   }
      // } catch (error) {
      //   console.error('Error fetching image/ profile image doesnt exist:', error)
      // }

      try {
        const getImg = await fetch(
          configJson.domainName + ':' + configJson.port + '/api/employeequery',
          {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              function: 'GetEmployeePicture',
              employee_id: userId,
              // employee_id: employeeQuery,
            }),
          }
        )

        const getImgCont = await getImg.json()

        if (getImg.status === 200) {
          const certArray = decodeBase64(getImgCont['message'])
          setImageProp(certArray)
        } else if (getImg.status === 404) {
          // Handle the case when status is not 200, e.g., error handling
          console.log('Image not found for this user.')
          setErrorHappened(true)
        }
      } catch (error) {
        // Handle any errors that occur during the fetch or JSON parsing
        console.error('An error occurred:', error)
      }
    }
  }

  function decodeBase64(data: any) {
    const decodedData = Buffer.from(data, 'base64')
    const blob = new Blob([decodedData], {type: 'image/jpeg'})
    const imageUrl = URL.createObjectURL(blob)
    return imageUrl
  }

  useEffect(() => {
    callImage()
  }, [])

  useEffect(() => {
    if (errorHappened != true) {
      const interval = setInterval(() => {
        callImage()
      }, callInterval)

      return () => clearInterval(interval)
    }
  }, [errorHappened])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' /> */}
          <img
            src={
              imageProp !== ''
                ? toAbsoluteUrl(imageProp)
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            alt='Logo'
          />
        </div>
        <HeaderUserMenu imageProps={imageProp} />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
