import {useEffect, useState, FC, useRef, useContext} from 'react'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {AuthContext} from '../../../../../hooks/authcontext'

const threshold = 2
const chartIndex = 'PerformanceAppraisal-Chart'

interface EmpAppraisalInfo {
  appraisalRatio: string
  appraisalScore: number
  dateModified: string
}

// Helper function to clean up color values
function cleanColor(color: any) {
  // remove any leading or trailing whitespace
  color = color.trim()
  // remove any leading # characters
  color = color.replace(/^#+/, '')
  // ensure the color string is exactly 6 characters long
  if (color.length < 6) {
    color = color.padStart(6, '0')
  } else if (color.length > 6) {
    color = color.slice(0, 6)
  }
  // add a leading # character
  color = '#' + color
  return color
}

export const PerformanceAppraisal: FC<{
  isCompact?: boolean
  employeeQuery?: string
  className?: string
}> = ({isCompact = false, employeeQuery, className}) => {
  const [empAppraisalInfo, setEmpAppraisalInfo] = useState<EmpAppraisalInfo>()
  const {userId} = useContext(AuthContext)
  const chartRef = useRef<HTMLDivElement>(null)
  const {mode} = useThemeMode()

  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  const chartSize = 100
  const chartLine = 20
  const chartRotate = 0

  let pieTest = {
    'total skills': 100,
    'total score': empAppraisalInfo ? empAppraisalInfo.appraisalScore * 100 : 0,
  }

  async function callEmpAppraisalInfo(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empAppraisalInfo = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetAppraisalScore',
            employee_id: employeeQuery,
          }),
        }
      )

      const empAppraisalInfoCont = await empAppraisalInfo.json()

      if (empAppraisalInfoCont['appraisal ratio']) {
        const appraisalInfo: EmpAppraisalInfo = {
          appraisalRatio: empAppraisalInfoCont['appraisal ratio'],
          appraisalScore: empAppraisalInfoCont['appraisal score'],
          dateModified: empAppraisalInfoCont['date modified'],
        }

        setEmpAppraisalInfo(appraisalInfo)
      }
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callEmpAppraisalInfo(employeeQuery)
    } else {
      callEmpAppraisalInfo(userId ? userId : undefined)
    }
  }, [employeeQuery])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, pieTest])

  const refreshChart = () => {
    if (!chartRef.current || !pieTest) {
      return
    }

    setTimeout(() => {
      initChart(
        chartSize,
        chartLine,
        chartRotate,
        pieTest['total skills'],
        pieTest['total score'],
        chartIndex,
        isCompact
      )
    }, 10)
  }

  const giveRating = (rating: number) => {
    switch (rating) {
      case 1:
        return (
          <>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
          </>
        )
        break
      case 2:
        return (
          <>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
          </>
        )
        break
      case 3:
        return (
          <>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid fa-star'></i>
            <i className='fa-solid fa-star'></i>
          </>
        )
        break
      case 4:
        return (
          <>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid fa-star'></i>
          </>
        )
        break
      case 5:
        return (
          <>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
            <i className='fa-solid text-primary fa-star'></i>
          </>
        )
        break

      default:
        break
    }
  }

  return (
    <div className={className}>
      <div className='h-100 d-flex flex-row'>
        <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
          <div className={`card mb-4 mb-xxl-8 h-100`}>
            <div className='card-header border-0 pt-5 '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Performance (Appraisal)</span>
              </h3>
            </div>
            {empAppraisalInfo ? (
              <div className='d-flex justify-content-center align-content-center '>
                <div className='card-body py-5 justify-content-center align-content-center'>
                  <div className='d-flex flex-center me-5 pt-2'>
                    <div
                      id={chartIndex}
                      ref={chartRef}
                      style={{minWidth: `${chartSize / 2}px`, minHeight: `${chartSize / 2}px`}}
                      data-kt-size={chartSize / 3}
                      data-kt-line={chartLine / 2}
                    ></div>
                    <div style={{width: '50px'}}></div>
                    <div className='fw-bolder text-white-700 text-xxl-end fs-2x'>
                      {Math.round(empAppraisalInfo.appraisalScore * 100)}%
                    </div>
                  </div>
                  <div className='table-responsive pt-10'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                      <tbody>
                        <tr>
                          <td className='text-dark fw-bold text-hover-primary mb-1 fs-5 '>
                            Performance Rating
                          </td>
                          {/* <td>{giveRating(3)}</td> */}
                          <td>{empAppraisalInfo.appraisalRatio}</td>
                        </tr>
                        <tr>
                          <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>Date</td>
                          <td>{empAppraisalInfo.dateModified}</td>
                        </tr>
                        <tr>
                          {/* {user?.['user type'] == 'entity-editor' && (
                                <div className='mb-10'>
                                  <label className='form-label'>Default input</label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='name@example.com'
                                  />
                                </div>
                              )} */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : showLoading ? (
              <>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                      There are no Performance (Appraisal) available. Please go to the Assessments
                      tab to see if you have any pending assessments!
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                  <span
                    className='spinner-border spinner-border-lg spinner-info'
                    style={{
                      width: '80px',
                      height: '80px',
                      border: ' var(--bs-spinner-border-width) solid #01a982',
                      borderRightColor: '#FFFFFF',
                      borderWidth: '10px',
                    }}
                  ></span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  number1: number,
  number2: number,
  chartIndex: string,
  isCompact: boolean
) {
  if (isCompact == true) {
    chartSize = chartSize / 2
    chartLine = chartLine / 2
    chartRotate = chartRotate / 2
  }
  const el = document.getElementById(chartIndex)
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  let percentage = number2 / number1 // Assuming the maximum grade is 100
  let color = cleanColor(
    getComputedStyle(document.documentElement).getPropertyValue('--bs-beginner-grade')
  ) // Default color for beginner

  if (percentage >= 0.75) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-proficient-grade')
    ) // Proficient (>= 100%)
  } else if (percentage >= 0.5) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-experienced-grade')
    ) // Experienced (>= 75%)
  } else if (percentage >= 0.25) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-intermediate-grade')
    ) // Intermediate (>= 50%)
  } else if (percentage <= 0.25) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-beginner-grade')
    ) // Beginner (>= 25%)
  }

  if (percentage <= threshold / 100) {
    percentage = threshold / 100 // Set percent to a specific value when it is zero
  }
  // Init 2
  //'#E4E6EF'
  drawCircle(getCSSVariableValue('--bs-gray-300'), options.lineWidth, number1 / number1)
  drawCircle(color, options.lineWidth, percentage)
}
