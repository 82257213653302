import {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import {ButtonCard} from '../../../_metronic/partials/widgets/_new/cards/ButtonCard'

import {AuthContext} from '../../../hooks/authcontext'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import AdminCreateUserModal from '../../components/modals/AdminCreateUserModal'
import AdminCreateEntityModal from '../../components/modals/AdminCreateEntityModal'
import AdminCreateCandidateModal from '../../components/modals/AdminCreateCandidateModal'
import {EntityInfo} from '../../../dataTypes'

const AdminDashboard: FC = () => {
  const intl = useIntl()
  const [openUserModal, setOpenUserModal] = useState(false)
  const [openEntityModal, setOpenEntityModal] = useState(false)
  const [openCandidateModal, setOpenCandidateModal] = useState(false)

  const [updateUser, setUpdateUser] = useState(true)
  const [updateEntity, setUpdateEntity] = useState(true)
  const [updateCandidate, setUpdateCandidate] = useState(true)

  const [entityData, setEntityData] = useState<EntityInfo[]>([])
  const {userId} = useContext(AuthContext)

  useEffect(() => {
    async function callEntInfo() {
      if (userId) {
        // console.log('TESTING')
        // console.log(user)
      }
    }
    callEntInfo()
  }, [])

  //#region CREATING

  const createNewEntity = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    // const newUserInfo = await fetch(
    //   process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({}),
    //   }
    // )

    // const newUserInfoCont = await newUserInfo.json()
  }

  const createNewCandidate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    // const newUserInfo = await fetch(
    //   process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({}),
    //   }
    // )

    // const newUserInfoCont = await newUserInfo.json()
  }
  //#endregion

  //#region Fetching Existing Info
  useEffect(() => {
    if (updateUser === true) {
      fetchUsers()
    }
    if (updateEntity === true) {
      fetchEntities()
    }
    if (updateCandidate === true) {
      fetchCandidates()
    }
  }, [updateUser, updateEntity, updateCandidate])

  const fetchUsers = () => {
    console.log('Fetching Users')
    setUpdateUser(false)
  }

  const fetchEntities = async () => {
    console.log('Fetching Entities')

    const getAllEntites = await fetch(
      process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/entity/all',
      {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
      }
    )

    const getAllEntitesCont = await getAllEntites.json()
    console.log(getAllEntitesCont)

    if (getAllEntites.status === 200 && getAllEntitesCont !== null) {
      const entData: EntityInfo[] = getAllEntitesCont.map((ent: any) => ({
        alias: ent.alias,
        name: ent.name,
        entity_id: ent.entity_id,
        last_updated: ent.last_updated,
        talent_pool_percentage: ent.talent_pool_percentage,
        total_score: ent.total_score,
      }))

      setEntityData(entData)
    }
    setUpdateEntity(false)
  }

  const fetchCandidates = () => {
    console.log('Fetching Candidates')
    setUpdateCandidate(false)
  }

  //#endregion

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADMIN.DASHBOARD'})}</PageTitle>

      <div>
        <div className='row g-2 mb-2 g-xl-2 min-h-50px'>
          {/* <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 h-100'>
            <Button className='w-100' onClick={() => setOpenUserModal(true)}>
              Create User
            </Button>
          </div> */}
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100'>
            <Button className='w-100' onClick={() => setOpenEntityModal(true)}>
              Create Entity
            </Button>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100 '>
            <Button variant='secondary' className='w-100' onClick={() => setOpenEntityModal(true)}>
              View All Entites
            </Button>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100'>
            <Button className='w-100' onClick={() => setOpenCandidateModal(true)}>
              Create Candidate
            </Button>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100'>
            <Button
              variant='secondary'
              className='w-100'
              onClick={() => setOpenCandidateModal(true)}
            >
              View All Candidates
            </Button>
          </div>
        </div>

        <div className='row g-2 mb-2 g-xl-2 min-h-300px'>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 min-h-100'>
            <div className='card h-100'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start me-auto '>
                  <Link to={'/entity-management'}>
                    <span className='card-label fw-bold fs-3 mb-1'>Entity List</span>
                  </Link>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='fixed-column' style={{minWidth: '80px'}}>
                          Entity ID
                        </th>
                        <th className='fixed-column' style={{minWidth: '100px'}}>
                          Name
                        </th>

                        <th className='fixed-column' style={{minWidth: '100px'}}>
                          Alias
                        </th>
                        <th className='fixed-column' style={{minWidth: '80px'}}>
                          Talent Pool %
                        </th>
                        <th className='fixed-column' style={{minWidth: '80px'}}>
                          Last Updated
                        </th>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entityData?.map((ent, index) => {
                        return (
                          <tr key={'ent-' + index}>
                            <td>{ent.entity_id}</td>
                            <td>{ent.name}</td>
                            <td>{ent.alias}</td>
                            <td>{ent.talent_pool_percentage}</td>
                            <td>{ent.last_updated}</td>
                            <td>{ent.total_score}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 min-h-100 '>
            <div className='card h-100'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start me-auto '>
                  <Link to={'/candidate-management'}>
                    <span className='card-label fw-bold fs-3 mb-1'>Candidates List</span>
                  </Link>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Name
                        </th>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Entity
                        </th>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>John Doe</td>
                        <td>HPE</td>
                        <td>20</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row g-2 mb-2 g-xl-2'>
          <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 h-100'>
            <div className='card h-100'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start me-auto'>
                  <Link to={'/user-management'}>
                    <span className='card-label fw-bold fs-3 mb-1'>User List</span>
                  </Link>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          User Type
                        </th>
                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Username
                        </th>

                        <th className='fixed-column' style={{minWidth: '150px'}}>
                          Password
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Admin</td>
                        <td>hpe-admin</td>
                        <td>ABC123</td>
                      </tr>
                      <tr>
                        <td>Admin</td>
                        <td>hpe-admin</td>
                        <td>ABC123</td>
                      </tr>
                      <tr>
                        <td>Admin</td>
                        <td>hpe-admin</td>
                        <td>ABC123</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AdminCreateUserModal
          size='lg'
          openModal={openUserModal}
          setOpenModal={setOpenUserModal}
          setUpdate={setUpdateUser}
        />

        <AdminCreateEntityModal
          size='lg'
          openModal={openEntityModal}
          setOpenModal={setOpenEntityModal}
          setUpdate={setUpdateEntity}
        />

        <AdminCreateCandidateModal
          size='xl'
          openModal={openCandidateModal}
          setOpenModal={setOpenCandidateModal}
          setUpdate={setUpdateCandidate}
        />
      </div>
    </>
  )
}

export {AdminDashboard}
