import React, {FC, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EntDashboard} from '../EntDashboard'
import ProfilePage from '../../modules/profile/client_profile/ProfilePage'
import {AdminDashboard} from '../admin/AdminDashboard'
import {EntManagerDashboard} from '../EntManagerDashboard'
import {AuthContext} from '../../../hooks/authcontext'

const DashboardPage: FC = () => {
  const intl = useIntl()
  let dashboardComponent = null

  const {userType} = useContext(AuthContext)
  console.log('Dashboard Wrapper')
  if (userType == 'admin' || userType == 'super-admin') {
    dashboardComponent = <AdminDashboard />
  } else if (userType == 'leadership') {
    dashboardComponent = <EntDashboard />
  } else if (userType == 'entity-editor') {
    dashboardComponent = <EntDashboard />
  } else if (userType == 'entity-viewer') {
    dashboardComponent = <EntDashboard />
  } else if (userType?.includes('entity')) {
    dashboardComponent = <EntDashboard />
  } else if (userType == 'employee') {
    dashboardComponent = <ProfilePage />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {dashboardComponent}
    </>
  )
}

const DashboardWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]} />
    <DashboardPage />
  </>
)

export {DashboardWrapper}
