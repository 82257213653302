import {FC} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'

type Props = {
  className?: string
  size?: 'lg' | 'sm' | 'xl'
  openModal?: any
  setOpenModal?: any
  setUpdate?: any
}

const AdminCreateEntityModal: FC<Props> = ({size, openModal, setOpenModal, setUpdate}) => {
  const createNewEntity = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const createEnt = await fetch(
      process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/entity',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: formData.get('id') as string,
          alias: formData.get('alias') as string,
          name: formData.get('name') as string,
          talent_pool_percentage: formData.get('talent-pool') as unknown as number,
        }),
      }
    )

    const createEntCont = await createEnt.json()
    console.log(createEntCont)

    setUpdate(true)
  }

  return (
    <Modal size={size} centered show={openModal} onHide={() => setOpenModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Entity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={createNewEntity}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Form.Label htmlFor='id'>Entity ID</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='id'
              id='id'
              placeholder='e.g. TZ'
            />
            <Form.Label htmlFor='name'>Entity Name</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='name'
              id='name'
              placeholder='e.g. TechZone Networking Services LLC'
            />
            <Form.Label htmlFor='alias'>Entity Alias</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='alias'
              id='alias'
              placeholder='e.g. TechZone'
            />
            <Form.Label htmlFor='email'>Entity Manager Email</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='email'
              id='email'
              placeholder='admin@techzone.com'
            />
            <Form.Label htmlFor='talent-pool'>Talent Pool Percentage</Form.Label>
            <Form.Control
              type='number'
              className='form-control form-control-lg form-control-solid mb-2'
              name='talent-pool'
              id='talent-pool'
              placeholder='70'
            />

            <div className='d-flex justify-content-between mt-2'>
              <Button variant='danger' onClick={() => setOpenModal(false)}>
                Close
              </Button>

              <Button type='submit'>Add</Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminCreateEntityModal
