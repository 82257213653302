import {FC, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'

type Props = {
  className?: string
  size?: 'lg' | 'sm' | 'xl'
  openModal?: any
  setOpenModal?: any
  setUpdate?: any
  userName?: any
  userEmail?: any
  userSelectedType?: any
}

const AdminCreateUserModal: FC<Props> = ({
  size,
  openModal,
  setOpenModal,
  setUpdate,
  userEmail,
  userName,
  userSelectedType,
}) => {
  const [userType, setUserType] = useState('admin')

  const getUserType = async () => {
    // const userInfo = await fetch(
    //   process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({}),
    //   }
    // )
    // const userInfoCont = await userInfo.json()
  }

  const createNewUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    // const newUserInfo = await fetch(
    //   process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify({}),
    //   }
    // )

    // const newUserInfoCont = await newUserInfo.json()

    setUpdate(true)
  }

  return (
    <Modal size={size} centered show={openModal} onHide={() => setOpenModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={createNewUser}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Form.Label htmlFor='gender'>User Level</Form.Label>
            <div className='d-flex mb-2'>
              <Button
                variant={userType === 'admin' ? 'primary' : 'secondary'}
                className='me-5'
                onClick={() => setUserType('admin')}
              >
                Admin
              </Button>
              <Button
                variant={userType === 'entity' ? 'primary' : 'secondary'}
                className='me-5'
                onClick={() => setUserType('entity')}
              >
                Entity
              </Button>
              <Button
                variant={userType === 'candidate' ? 'primary' : 'secondary'}
                className='me-5'
                onClick={() => setUserType('candidate')}
              >
                Candidate
              </Button>
            </div>
            <Form.Label htmlFor='id'>Username</Form.Label>

            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='id'
              id='id'
              placeholder='johndoe'
            />
            <Form.Label htmlFor='name'>Email</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='name'
              id='name'
              placeholder='john@company.com'
            />
            <Form.Label htmlFor='alias'>Password</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='alias'
              id='alias'
              placeholder='p@S$w0rD'
            />
            <Form.Label htmlFor='email'>User Type</Form.Label>
            <Form.Control
              type='text'
              className='form-control form-control-lg form-control-solid mb-2'
              name='email'
              id='email'
              placeholder='admin@techzone.com'
            />

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button variant='danger' onClick={() => setOpenModal(false)}>
                Close
              </Button>

              <Button type='submit'>Add</Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminCreateUserModal
