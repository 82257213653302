import {useEffect, useState, FC, useContext} from 'react'
import {EmployeeDetails} from '../../../../dataTypes'
import {AuthContext} from '../../../../hooks/authcontext'

type Employee = {
  number: string
  name: string
  position: string
  division: string
  department: string
  section: string
  score: number
  technicalScore: number
  PotentialSkillsScore: number
  PotentialSkillsRatio: number
  techSkillsRatio: number
  'employee id': string
}

const UserNumberListDropDown: FC<{
  selectedEmployee?: string
  setSelectedEmployee?: any
}> = ({selectedEmployee, setSelectedEmployee}) => {
  const [originalData, setOriginalData] = useState<Employee[]>([])
  const {userId} = useContext(AuthContext)
  const [employeeData, setEmployeeData] = useState<EmployeeDetails[]>([])

  useEffect(() => {
    async function callEmpData() {
      if (userId) {
        const allEmpDataSet = await fetch(
          process.env.REACT_APP_SERVER_NAME +
            ':' +
            process.env.REACT_APP_SERVER_PORT +
            '/api/employee',
          {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              entity_id: userId,
            }),
          }
        )

        const allEmpDataCont = await allEmpDataSet.json()
        const employeeData = allEmpDataCont.employees.map((emp: any) => {
          if (emp['employee score'] !== '-') {
            const empScorePercentage = Math.round(emp['employee score'] * 100)
            return {...emp, 'employee score': empScorePercentage}
          } else {
            return {...emp, 'employee score': 0}
          }
        })

        const empDataArray: EmployeeDetails[] = employeeData.map((employee: any) => ({
          'employee id': employee['employee id'],
          'employee number': employee['employee number'],
          name: employee.name,
          entity: employee.entity,
          email: employee.email,
          nationality: employee.nationality,
          gender: employee.gender,
          dob: employee.dob,
          'job title': employee['job title'],
          department: employee.department,
          section: employee.section,
          division: employee.division,
          sector: employee.sector,
          'date of joining': employee['date of joining'],
          'years of experience': employee['years of experience'],
          'role reporting to': employee['role reporting to'],
          'reporting to you': employee['reporting to you'],

          'potential score': employee['potential score'],
          'performance technical score': employee['performance technical score'],
          'performance appraisal score': employee['performance appraisal score'],
          'line manager score': employee['line manager score'],
          'employee score': employee['employee score'],
          'talent pool': employee['talent pool'],
          'last login': employee['last login'],
          'function category': employee['function category'],
          'test category': employee['test category'],
          'assessment category': employee['assessment category'],
          'job category': employee['job category'],
          'job level': employee['job level'],
        }))
        setEmployeeData(empDataArray)
        const dummyData: Employee[] = employeeData.map((employee: any) => {
          return {
            name: employee.name,
            number: employee['employee number'],
            position: employee['job title'],
            division: employee['division'],
            section: employee['section'],
            department: employee['department'],
            score: employee['employee score'],
            technicalScore: employee['performance technical score'],
            potentialSkillsScore: employee['potential score'],
            appraisalScore: employee['performance appraisal score'],
            lineManagerScore: employee['line manager score'],
            'employee id': employee['employee id'],
          }
        })

        setOriginalData(dummyData)
        setSelectedEmployee('')
      }
    }
    callEmpData()
  }, [])

  return (
    <div data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Candidate Number List</div>
        <select
          className='form-select '
          data-kt-select2='true'
          data-placeholder='Select an employee'
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value)
          }}
        >
          <option value=''>Select a candidate via their ID number</option>
          {originalData.map((employee, index) => (
            <option
              className='form-check'
              key={employee['employee id']}
              value={employee['employee id']}
            >
              {employee.number}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export {UserNumberListDropDown}
