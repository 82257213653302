/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {Link} from 'react-router-dom'
import {DynamicSlider} from './DynamicSlider'
import {TalentPoolController} from './TalentPoolController'
import {AuthContext} from '../../../../../hooks/authcontext'

type Props = {
  className: string
  title?: string
  subtitle: string
  color?: string
  img?: string
  fontColor?: string
}

type Employee = {
  name: string
  position: string
  division: string
  department: string
  section: string
  score: number
  technicalScore: number
  appraisalScore: number
  lineManagerScore: number
  potentialSkillsScore: number
  dob: string
  gender: string
  nationality: string
  talentPool: string
  email: string
  sector: string
  yearsOfExp: string
  'employee id': string
  'employee number': string
}

type talentPerc = {
  talentPerc: number
}

const EntityTalentInfo: FC<Props> = ({className}) => {
  const {mode} = useThemeMode()
  const [originalData, setOriginalData] = useState<Employee[]>([])
  const [talentPercentage, setTalentPercentage] = useState<talentPerc>()
  const {userId, userType} = useContext(AuthContext)
  const [showLoading, setShowLoading] = useState(false)
  const [showSlider, setShowslider] = useState(false)
  const [showTalentPoolController, setShowTalentPoolController] = useState(false)

  const warningTime = 3000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  async function callTalentInfo() {
    if (userId) {
      const allEmpDataSet = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employee',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            entity_id: userId,
          }),
        }
      )

      const allEmpDataCont = await allEmpDataSet.json()

      const employeeData = allEmpDataCont.employees.map((emp: any) => {
        if (emp['employee score'] !== '-') {
          const empScorePercentage = Math.round(emp['employee score'] * 100)
          return {...emp, 'employee score': empScorePercentage}
        } else {
          return {...emp, 'employee score': 0}
        }
      })

      const dummyData: Employee[] = employeeData.map((employee: any) => {
        return {
          name: employee.name,
          position: employee['job title'],
          dob: employee.dob,
          division: employee['division'],
          section: employee['section'],
          department: employee['department'],
          score: employee['employee score'],
          technicalScore: employee['performance technical score'],
          potentialSkillsScore: employee['potential score'],
          appraisalScore: employee['performance appraisal score'],
          lineManagerScore: employee['line manager score'],
          gender: employee.gender,
          nationality: employee.nationality,
          talentPool: employee['talent pool'],
          email: employee.email,
          sector: employee.sector,
          yearsOfExp: employee['years of experience'],
          'employee id': employee['employee id'],
          'employee number': employee['employee number'],
        }
      })

      const entData = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEntityData',
            entity_id: userId,
          }),
        }
      )
      const entDataCont = await entData.json()

      if (entDataCont) {
        const talentPercentage: talentPerc = {
          talentPerc: entDataCont['talent pool percentage'] * 100,
        }
        setTalentPercentage(talentPercentage)
      }
      setOriginalData(dummyData)
    }
  }

  useEffect(() => {
    callTalentInfo()
  }, [])

  function getPeopleInTotal(): number {
    let talent = 0
    let totalCount = 0
    originalData.map((emp, index) => {
      totalCount++
    })
    return totalCount
  }

  function getPeopleFromTalent(): number {
    let talent = 0
    let totalCount = 0
    originalData.map((emp, index) => {
      totalCount++
      if (emp.talentPool === 'true') {
        talent++
      }
    })
    const talentPeoplePerc = talent + '/' + totalCount
    return talent
  }

  // Function to refresh data and show/hide TalentPoolController
  const toggleShowEditButton = () => {
    setShowslider((prevState) => !prevState)
    setShowTalentPoolController((prevState) => !prevState)

    // Call the function to fetch updated data
    callTalentInfo()

    // Console log to indicate that the data has been refreshed
    console.log('Data has been refreshed')
  }

  return (
    <div className={`card ${className}`}>
      {/* Show the icon based on the condition */}
      {userType === 'entity-editor' && (
        <i
          className={
            showTalentPoolController
              ? 'fa-solid fa-xmark text-hover-dark fs-2'
              : 'fa-solid fa-pen-to-square text-hover-dark fs-2'
          }
          onClick={toggleShowEditButton}
          style={{
            position: 'absolute',
            top: '8%',
            right: '10%',
            zIndex: 1,
          }}
        ></i>
      )}

      {/* Show TalentPoolController component or other content based on condition */}
      {showTalentPoolController ? (
        <TalentPoolController />
      ) : (
        <>
          {/* Title section */}
          <div className={`card card-flush bgi-no-repeat bgi-size-contain`}>
            <div className='card-header border-0 pt-5'>
              <div className='card-title d-flex flex-column'>
                <Link to={'/candidate-overview?talent'}>
                  <span className='card-label fw-bold fs-3 mb-1'>Talent Info</span>
                </Link>
              </div>
            </div>
          </div>
          {/* Talent-related information with padding */}
          {originalData ? (
            <div className='card-body pt-0 pb-4 d-flex align-items-center'>
              <div className='d-flex pt-0 overflow-scroll flex-column h-100 w-100'>
                <div className='mb-4'>
                  The Talent Pool allows you to get a quick overview of the highest performing
                  candidates within the organization.
                </div>
                <table className='table-responsive table table-row-dashed table-row-gray-300 align-middle gs-0  gy-2 table-fixed'>
                  <tbody>
                    <tr>
                      <td>
                        <div className='d-flex mt-2 mb-2 text-dark fw-bold text-hover-primary fs-6'>
                          Total No. Of Candidates
                        </div>
                      </td>
                      <td>
                        <div className='d-flex mt-2 mb-2 '>{getPeopleInTotal()}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex mt-2 mb-2 text-dark fw-bold text-hover-primary fs-6'>
                          Candidates in Talent Pool
                        </div>
                      </td>
                      <td>
                        <div className='d-flex mt-2 mb-2'>{getPeopleFromTalent()}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex mt-2 mb-2 text-dark fw-bold text-hover-primary fs-6'>
                          Talent Pool Threshold
                        </div>
                      </td>
                      <td>
                        <div className='d-flex mt-2 mb-2'>
                          {Math.round((talentPercentage as talentPerc)?.talentPerc)}%
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : showLoading ? (
            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              <span className='text-center fs-5 fw-medium'>
                There are no people in the talent pool
              </span>
            </div>
          ) : (
            <div className='d-flex justify-content-center'>
              <span
                className='spinner-border spinner-border-lg spinner-info'
                style={{
                  width: '40px',
                  height: '40px',
                  border: 'var(--bs-spinner-border-width) solid #01a982',
                  borderRightColor: 'var(--bs-light)',
                  borderWidth: '5px',
                }}
              ></span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export {EntityTalentInfo}
