import React, {FC, useContext, useEffect, useState} from 'react'
import {TrainingPathsTable} from '../../../../../_metronic/partials/widgets'
import {TrainingCourse} from '../../../../../dataTypes'
import {useCallInterval} from '../../../../../hooks/CallTimeIntervalContext'
import {AuthContext} from '../../../../../hooks/authcontext'

interface TrainingPathsProps {
  isCompact?: boolean
  employeeQuery?: string
  onSelectSkillNames?: (selectedNames: string[]) => void
  selectedSkillNames?: string[]
  onMinusButtonClick?: (courseIndex: number, courseCategory: string) => void
  minus?: boolean
  callFunc?: string
  potentialEmpty?: boolean
  prevSelectedSkillNamesCont?: string[]
  onPrevSelectSkillNames?: (prevSelectedSkillNames: string[]) => void // Add the onSelectSkillNames prop
}

interface TrainingCourseWithCategory extends TrainingCourse {
  'course category': string
}
interface TrainingCourseWithLevel extends TrainingCourseWithCategory {
  'course level': string
}

export const TrainingPaths: FC<TrainingPathsProps> = ({
  isCompact = false,
  employeeQuery,
  onSelectSkillNames,
  selectedSkillNames: propSelectedSkillNames,
  minus = false,
  callFunc = 'AllEmployeeData',
  potentialEmpty,
  prevSelectedSkillNamesCont,
  onPrevSelectSkillNames,
}) => {
  const [employeeACourseTrainings, setEmployeeACourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [employeeCCourseTrainings, setEmployeeCCourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [employeeFCourseTrainings, setEmployeeFCourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [selectedSkillNames, setSelectedSkillNames] = useState<string[]>([])
  const [prevSelectedSkillNames, setPrevSelectedSkillNames] = useState<string[]>([])
  const [filteredACourseTrainings, setFilteredACourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [filteredCCourseTrainings, setFilteredCCourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [filteredFCourseTrainings, setFilteredFCourseTrainings] = useState<
    TrainingCourseWithCategory[]
  >([])
  const [allFilteredCoursesZero, setAllFilteredCoursesZero] = useState(false)
  const [removedCourses, setRemovedCourses] = useState<TrainingCourseWithLevel[]>([])
  const [showLoading, setShowLoading] = useState(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertClassName, setAlertClassName] = useState<string>('alert-success') // Class name for the alert

  const warningTime = 5000

  const {userId} = useContext(AuthContext)
  const {callInterval} = useCallInterval()

  async function callEmpTrainingData(employeeQuery: string | undefined) {
    const empTrainingDData = await fetch(
      process.env.REACT_APP_SERVER_NAME +
        ':' +
        process.env.REACT_APP_SERVER_PORT +
        '/api/employeequery',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: callFunc,
          employee_id: employeeQuery,
        }),
      }
    )

    const empTrainingDataCont = await empTrainingDData.json()
    //console.log(empTrainingDataCont)
    const aCourseTrainings: TrainingCourseWithCategory[] = empTrainingDataCont['acourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course category': course['course competency'],
        'course type': course['course type'],
      })
    )

    const cCourseTrainings: TrainingCourseWithCategory[] = empTrainingDataCont['ccourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course category': course['course competency'],
        'course type': course['course type'],
      })
    )

    const fCourseTrainings: TrainingCourseWithCategory[] = empTrainingDataCont['fcourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course category': course['course competency'],
        'course type': course['course type'],
      })
    )
    const prevSelectedCont = empTrainingDataCont['categories']?.map((cat: string) => cat)

    setEmployeeACourseTrainings(aCourseTrainings || [])
    setEmployeeCCourseTrainings(cCourseTrainings || [])
    setEmployeeFCourseTrainings(fCourseTrainings || [])
    setPrevSelectedSkillNames(prevSelectedCont || [])
  }

  async function sendEmpTrainingData(employeeQuery: string) {
    const empTrainingDData = await fetch(
      process.env.REACT_APP_SERVER_NAME +
        ':' +
        process.env.REACT_APP_SERVER_PORT +
        '/api/settrainings',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          employee_id: employeeQuery,
          acourses: filteredACourseTrainings,
          ccourses: filteredCCourseTrainings,
          fcourses: filteredFCourseTrainings,
        }),
      }
    )

    const empTrainingDataCont = await empTrainingDData.json()

    //if success update
    if (empTrainingDData.status == 200) {
      setAlertMessage('Success' || '')
      setAlertClassName(empTrainingDData.status !== null ? 'alert-success' : 'alert-danger')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    } else {
      setAlertMessage('Error setting training')
      setAlertClassName(empTrainingDData.status !== null ? 'alert-success' : 'alert-danger')
    }
  }

  //#region Use Effects

  useEffect(() => {
    if (onPrevSelectSkillNames) {
      onPrevSelectSkillNames(prevSelectedSkillNames || [])
    }
  }, [prevSelectedSkillNames, onPrevSelectSkillNames])

  useEffect(() => {
    if (minus == true) {
      if (employeeQuery) {
        callEmpTrainingData(employeeQuery)
      } else {
        callEmpTrainingData(userId ? userId : undefined)
      }
    } else if (minus == false) {
      if (employeeQuery) {
        callEmpTrainingData(employeeQuery)
      } else {
        callEmpTrainingData(userId ? userId : undefined)
      }

      const interval = setInterval(() => {
        if (employeeQuery) {
          callEmpTrainingData(employeeQuery)
        } else {
          callEmpTrainingData(userId ? userId : undefined)
        }
      }, callInterval)

      return () => clearInterval(interval)
    }
  }, [employeeQuery])

  useEffect(() => {
    if (propSelectedSkillNames) {
      setSelectedSkillNames(propSelectedSkillNames)
    }
  }, [propSelectedSkillNames])

  useEffect(() => {
    filterTrainingCourses()
  }, [
    selectedSkillNames,
    employeeACourseTrainings,
    employeeCCourseTrainings,
    employeeFCourseTrainings,
  ])
  useEffect(() => {}, [
    filteredACourseTrainings,
    filteredCCourseTrainings,
    filteredFCourseTrainings,
  ])
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (allFilteredCoursesZero) {
      setFilteredACourseTrainings(employeeACourseTrainings)
      setFilteredCCourseTrainings(employeeCCourseTrainings)
      setFilteredFCourseTrainings(employeeFCourseTrainings)
    }
  }, [allFilteredCoursesZero])

  useEffect(() => {
    const allFilteredCoursesZero2 =
      filteredACourseTrainings.length === 0 &&
      filteredCCourseTrainings.length === 0 &&
      filteredFCourseTrainings.length === 0

    setAllFilteredCoursesZero(allFilteredCoursesZero2)
  }, [filteredACourseTrainings, filteredCCourseTrainings, filteredFCourseTrainings])

  //#endregion
  useEffect(() => {}, [removedCourses])

  const filterCoursesByCategory = (
    courses: TrainingCourseWithCategory[],
    selectedCategories: string[]
  ) => {
    if (selectedCategories.length === 0) {
      return courses // Return all courses if no categories are selected
    } else {
      return courses.filter((course) =>
        selectedCategories.some(
          (selectedCategory) =>
            course['course category'].toLowerCase() === selectedCategory.toLowerCase()
        )
      )
    }
  }

  const filterTrainingCourses = () => {
    let filteredACourses = employeeACourseTrainings
    let filteredCCourses = employeeCCourseTrainings
    let filteredFCourses = employeeFCourseTrainings

    if (selectedSkillNames.length > 0) {
      filteredACourses = filterCoursesByCategory(employeeACourseTrainings, selectedSkillNames)
      filteredCCourses = filterCoursesByCategory(employeeCCourseTrainings, selectedSkillNames)
      filteredFCourses = filterCoursesByCategory(employeeFCourseTrainings, selectedSkillNames)
    }

    setFilteredACourseTrainings(filteredACourses)
    setFilteredCCourseTrainings(filteredCCourses)
    setFilteredFCourseTrainings(filteredFCourses)
  }

  const handleMinusButtonClick = (courseIndex: number, courseCategory: string) => {
    let removedCourse: TrainingCourseWithLevel

    switch (courseCategory) {
      case 'ACourse':
        const updatedACourseTrainings = [...filteredACourseTrainings]
        removedCourse = {
          ...updatedACourseTrainings.splice(courseIndex, 1)[0],
          'course level': courseCategory,
        }
        setFilteredACourseTrainings(updatedACourseTrainings)
        break
      case 'CCourse':
        const updatedCCourseTrainings = [...filteredCCourseTrainings]
        removedCourse = {
          ...updatedCCourseTrainings.splice(courseIndex, 1)[0],
          'course level': courseCategory,
        }
        setFilteredCCourseTrainings(updatedCCourseTrainings)
        break
      case 'FCourse':
        const updatedFCourseTrainings = [...filteredFCourseTrainings]
        removedCourse = {
          ...updatedFCourseTrainings.splice(courseIndex, 1)[0],
          'course level': courseCategory,
        }
        setFilteredFCourseTrainings(updatedFCourseTrainings)
        break
      default:
        removedCourse = {} as TrainingCourseWithLevel
        break
    }

    // Save the removed course to the state
    setRemovedCourses((prev) => [...prev, removedCourse])
  }

  const handleUndoButtonClick = () => {
    const lastRemovedCourse = removedCourses[removedCourses.length - 1]
    if (lastRemovedCourse) {
      switch (lastRemovedCourse['course level']) {
        case 'ACourse':
          setFilteredACourseTrainings((prev) => [...prev, lastRemovedCourse])
          break
        case 'CCourse':
          setFilteredCCourseTrainings((prev) => [...prev, lastRemovedCourse])
          break
        case 'FCourse':
          setFilteredFCourseTrainings((prev) => [...prev, lastRemovedCourse])
          break
        default:
          break
      }

      // Remove the last item from removedCourses by creating a new array without the last removed course
      setRemovedCourses((prev) => prev.slice(0, prev.length - 1))
    }
  }
  const handleSaveAndLogClick = () => {
    if (employeeQuery) {
      sendEmpTrainingData(employeeQuery)
    }
  }

  return (
    <div className='h-100'>
      <div className={`card  mb-2 h-75px`}>
        <div className='card card-header border-0 pt-5 d-flex flex-end'>
          <h3 className='card-title align-items-center flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Training Path </span>
          </h3>
          {minus &&
            (potentialEmpty == false ? (
              <>
                <div
                  className='d-flex justify-content-between me-4'
                  style={{position: 'absolute', right: '0', height: '50px'}}
                >
                  {showAlert && (
                    <div className={`alert ${alertClassName}  pb-10 me-2 `}>{alertMessage}</div>
                  )}

                  <button className='btn btn-light me-2' onClick={handleUndoButtonClick}>
                    Undo
                  </button>
                  <button className='btn btn-primary' onClick={handleSaveAndLogClick}>
                    Save Training
                  </button>
                </div>
              </>
            ) : (
              <div></div>
            ))}
        </div>
      </div>
      <TrainingPathsTable
        className='mb-4 mb-xxl-8 col-4 mt-0'
        isCompact={isCompact}
        aCourseTraining={filteredACourseTrainings}
        cCourseTraining={filteredCCourseTrainings}
        fCourseTraining={filteredFCourseTrainings}
        onMinusButtonClick={handleMinusButtonClick}
        minus={minus}
        potentialEmpty={potentialEmpty}
      />
    </div>
  )
}
