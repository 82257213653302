import {FC, useContext, useEffect, useState} from 'react'

import {Assesments} from './employee_components/Assesments'
import {PerformanceTechnical} from './employee_components/Performance(Technical)'
import {PotentialSkills} from './employee_components/Potenital'
import {EmpChart} from '../../../../_metronic/partials/widgets/charts/EmpChart'
import {ScoreBreakdownTable} from './ScoreBreakdownTable'
import {TrainingPaths} from './employee_components/TrainingPaths'
import {PerformanceAppraisal} from './employee_components/Performance(Appraisal)'
import {LineManager} from './employee_components/LineManager'
import {AuthContext} from '../../../../hooks/authcontext'

type Employee = {
  name: string
  totalScore: number
  technicalScore: number
  potentialScore: number
  appraisalScore: number
  lmScore: number
  potentialRatio: number
  technicalRatio: number
  appraisalRatio: number
  lmRatio: number
  'employee id': string
}
export const Overview: FC<{employeeQuery?: string}> = ({employeeQuery}) => {
  const [empData, setEmpData] = useState<Employee>()
  const {userId} = useContext(AuthContext)

  async function callEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEmployeeData',
            employee_id: employeeQuery,
          }),
        }
      )

      const allEmpDataCont = await allEmpData.json()

      const dummyData: Employee = {
        name: allEmpDataCont.employee.name,

        totalScore: allEmpDataCont.employee['employee score'],
        technicalScore: allEmpDataCont.employee['performance technical score'],
        potentialScore: allEmpDataCont.employee['potential score'],
        appraisalScore: allEmpDataCont.employee['performance appraisal score'],
        lmScore: allEmpDataCont.employee['line manager score'],
        'employee id': allEmpDataCont.employee['employee id'],
        potentialRatio: allEmpDataCont['employee ratios']['potential skill ratio'] * 100,
        appraisalRatio: allEmpDataCont['employee ratios']['appraisal skill ratio'] * 100,
        lmRatio: allEmpDataCont['employee ratios']['line manager skill ratio'] * 100,
        technicalRatio: allEmpDataCont['employee ratios']['technical skill ratio'] * 100,
      }

      if (dummyData) setEmpData(dummyData)
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callEmpData(employeeQuery)
    } else {
      callEmpData(userId ? userId : undefined)
    }
  }, [employeeQuery, userId])

  return (
    <div className='col-xl-12'>
      <div className='row g-2 g-xxl-2 mb-5' style={{height: '200px'}}>
        <div className='col-xl-12 h-100'>
          {employeeQuery ? (
            <EmpChart
              className='h-100'
              title='Total Score'
              isCompact={true}
              employeeQuery={employeeQuery}
            />
          ) : (
            <EmpChart className='h-100 p-2 m-0' title='Total Score' isCompact={true} />
          )}
        </div>
      </div>
      <div className='row g-2 g-xxl-2 mb-5' style={{height: '400px'}}>
        <div className='col-xl-4 h-100 d-flex flex-column'>
          {employeeQuery ? (
            <ScoreBreakdownTable
              className='flex-fill'
              isCompact={true}
              employeeQuery={employeeQuery}
            />
          ) : (
            <ScoreBreakdownTable className='flex-fill' isCompact={true} />
          )}
        </div>
        <div className='col-xl-8 h-100'>
          {employeeQuery ? (
            <Assesments className='h-100' isCompact={true} employeeQuery={employeeQuery} />
          ) : (
            <Assesments className='h-100' isCompact={true} />
          )}
        </div>
      </div>
      {(empData?.technicalRatio !== 0 ||
        empData?.appraisalRatio !== 0 ||
        empData?.lmRatio !== 0) && (
        <div className='row g-2 g-xxl-2 mb-5' style={{height: '500px'}}>
          {empData?.technicalRatio !== 0 && (
            <div className='col-xl-4 h-100'>
              {employeeQuery ? (
                <PerformanceTechnical
                  className='h-100'
                  isCompact={true}
                  employeeQuery={employeeQuery}
                />
              ) : (
                <PerformanceTechnical className='h-100' isCompact={true} />
              )}
            </div>
          )}

          {empData?.appraisalRatio !== 0 && (
            <div className='col-xl-4 h-100'>
              {employeeQuery ? (
                <PerformanceAppraisal
                  className='h-100'
                  isCompact={true}
                  employeeQuery={employeeQuery}
                />
              ) : (
                <PerformanceAppraisal className='h-100' isCompact={true} />
              )}
            </div>
          )}
          {empData?.lmRatio !== 0 && (
            <div className='col-xl-4 h-100'>
              {employeeQuery ? (
                <LineManager className='h-100' isCompact={true} employeeQuery={employeeQuery} />
              ) : (
                <LineManager className='h-100' isCompact={true} />
              )}
            </div>
          )}
        </div>
      )}

      {empData?.potentialRatio !== 0 && (
        <div className='row g-2 g-xxl-2' style={{height: '500px', marginBottom: '7rem'}}>
          <div className='col-xl-12 h-100'>
            {employeeQuery ? (
              <PotentialSkills className='h-100' isCompact={true} employeeQuery={employeeQuery} />
            ) : (
              <PotentialSkills className='h-100' isCompact={true} />
            )}
          </div>
        </div>
      )}

      <div className='row g-2 g-xxl-2' style={{height: '500px', marginBottom: '4rem'}}>
        <div className='col-xl-12 h-100'>
          {employeeQuery ? (
            <TrainingPaths isCompact={true} employeeQuery={employeeQuery} minus={false} />
          ) : (
            <TrainingPaths isCompact={true} minus={false} />
          )}
        </div>
      </div>
    </div>
  )
}
