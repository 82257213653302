import {FC, useState} from 'react'

interface TableColumn {
  id: number
  value: string
  label: string
  isFixed?: boolean
  minWidth?: number
}

type Props = {
  className?: string
}
const SortableTable: FC<Props> = ({className}) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [sortField, setSortField] = useState<string>('score')

  const tableColumnsInfo: TableColumn[] = [
    {
      id: 0,
      value: 'candidate id',
      label: 'Candidate ID',
      isFixed: true,
    },
    {
      id: 1,
      value: 'talentPool',
      label: 'Talent Pool',
      isFixed: false,
    },
    {
      id: 2,
      value: 'candidate',
      label: 'Candidate',
      isFixed: false,
    },
    {
      id: 3,
      value: 'gender',
      label: 'Gender',
      isFixed: false,
    },
    {
      id: 4,
      value: 'age',
      label: 'Age',
      isFixed: false,
    },
    {
      id: 5,
      value: 'nationality',
      label: 'Nationality',
      isFixed: false,
    },
    {
      id: 6,
      value: 'email',
      label: 'Email',
      isFixed: false,
    },
    {
      id: 7,
      value: 'position',
      label: 'Position',
      isFixed: false,
    },
    {
      id: 8,
      value: 'division',
      label: 'Division',
      isFixed: false,
    },
    {
      id: 9,
      value: 'section',
      label: 'Section',
      isFixed: false,
    },
    {
      id: 10,
      value: 'department',
      label: 'Department',
      isFixed: false,
    },
    {
      id: 11,
      value: 'sector',
      label: 'Sector',
      isFixed: false,
    },
    {
      id: 12,
      value: 'yearsOfExp',
      label: 'Years of Experience',
      isFixed: false,
    },
    {
      id: 13,
      value: 'score',
      label: 'Score',
      isFixed: true,
    },
  ]

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('desc')
    }
  }
  return (
    <div className='table-responsive'>
      <table
        className={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed`}
      >
        <thead>
          <tr className='fw-bold text-muted'>
            {tableColumnsInfo.map((colName) => {
              return (
                <th
                  className='fixed-column'
                  key={'overallTable'}
                  onClick={() => handleSort(colName.value)}
                >
                  {colName.label}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Hello</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export {SortableTable}
