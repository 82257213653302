/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, FC, useContext} from 'react'
import {EducationTimeline} from '../../../../../_metronic/partials/widgets'
import {EmployeeEducation} from '../../../../../dataTypes'
import {Button, Modal} from 'react-bootstrap'
import {AuthContext} from '../../../../../hooks/authcontext'
export const Education: FC = () => {
  const [employeeEducation, setEmployeeEducation] = useState<EmployeeEducation[]>([])
  const [educationUpdated, setEducationUpdated] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const {userId} = useContext(AuthContext)
  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  const handleButtonClick = (status: string) => {
    setSelectedStatus(status)
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    if (userId) {
      const newEducationItem: EmployeeEducation = {
        'education id': Date.now(),
        'employee id': userId,
        school: formData.get('school') as string,
        country: formData.get('country') as string,
        degree: formData.get('degree') as string,
        major: formData.get('major') as string,
        status: selectedStatus as string,
        'year completed': formData.get('year completed') as string,
        competencies: formData.get('competencies') as string,
        accomplishments: formData.get('accomplishments') as string,
      }
      sendEducation(newEducationItem)
    }
  }

  async function callEducation() {
    if (userId) {
      const getEducation = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetEmployeeEducation',
            employee_id: userId,
          }),
        }
      )

      const getEducationCont = await getEducation.json()
      const educationArray: EmployeeEducation[] = getEducationCont['Employee Education'].map(
        (assessment: any) => ({
          'education id': assessment['education id'],
          'employee id': assessment['employee id'],
          school: assessment['school'],
          country: assessment['country'],
          'year completed': assessment['year completed'],
          degree: assessment['degree'],
          major: assessment['major'],
          status: assessment['status'],
          competencies: assessment['competencies'],
          accomplishments: assessment['accomplishments'],
        })
      )

      setEmployeeEducation(educationArray)
    }
  }

  async function sendEducation(education: EmployeeEducation) {
    if (userId) {
      const sendEducation = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeeeducation',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            employee_id: userId,
            school: education.school,
            country: education.country,
            degree: education.degree,
            major: education.major,
            status: education.status,
            competencies: education.competencies,
            accomplishments: education.accomplishments,
            year_completed: education['year completed'],
          }),
        }
      )

      if (sendEducation.status === 200) {
        setEducationUpdated(true)
        setShowModal(false)
      }
    }
  }

  useEffect(() => {
    callEducation()
  }, [educationUpdated])

  return (
    <>
      <div className='row g-5 g-xxl-8 h-100'>
        {employeeEducation.length !== 0 ? (
          <EducationTimeline
            className='mb-5 mb-xxl-8'
            employeeEducation={employeeEducation}
            setShowModal={setShowModal}
          />
        ) : showLoading ? (
          <div className='d-flex flex-row'>
            <div style={{height: '100%', width: '100%'}}>
              <div className={`card  mb-4 mb-xxl-8 h-100`}>
                <div className='card-header border-0 pt-5 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Education</span>
                  </h3>
                  <div className='card-toolbar'>
                    <Button
                      onClick={() => setShowModal(true)}
                      className='btn btn-primary'
                      data-target='Form '
                    >
                      Add Education
                    </Button>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <div className='d-flex justify-content-center m-5 fw-bold fs-4'>
                      There is no Education History entered. Please click the "Add Education" button
                      to add your Education History!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='card-body pt-2 pb-4 d-flex justify-content-center '>
            <span
              className='spinner-border spinner-border-lg spinner-info'
              style={{
                width: '80px',
                height: '80px',
                border: ' var(--bs-spinner-border-width) solid #01a982',
                borderRightColor: 'var(--bs-light)',
                borderWidth: '10px',
              }}
            ></span>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Education</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='school'>School:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='school'
                id='school'
                placeholder='Enter school name'
                style={{marginBottom: '10px'}}
              />

              <label htmlFor='country'>Country:</label>
              <select
                className='form-control form-control-lg form-control-solid'
                name='country'
                id='country'
                style={{marginBottom: '10px'}}
              >
                <option value=''>Select country</option>
                <option value='Australia'>Australia</option>
                <option value='Canada'>Canada</option>
                <option value='Saudi Arabia'>Saudi Arabia</option>
                <option value='UAE'>United Arab Emirates</option>
                <option value='UK'>United Kingdom</option>
                <option value='USA'>United States of America</option>
              </select>

              <label>Status:</label>
              <div style={{display: 'flex', marginBottom: '10px'}}>
                <Button
                  variant={selectedStatus === 'Complete' ? 'primary' : 'secondary'}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => handleButtonClick('Complete')}
                >
                  Graduate
                </Button>
                <Button
                  variant={selectedStatus === 'In Progress' ? 'primary' : 'secondary'}
                  onClick={() => handleButtonClick('In Progress')}
                >
                  Student
                </Button>
              </div>

              <label htmlFor='degree'>Degree:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='degree'
                id='degree'
                placeholder='Enter degree'
                style={{marginBottom: '10px'}}
              />

              <label htmlFor='major'>Major:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='major'
                id='major'
                placeholder='Enter major'
                style={{marginBottom: '10px'}}
              />

              <label htmlFor='year completed'>Year of Completion:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='year completed'
                id='year completed'
                placeholder='Enter year of completion'
                style={{marginBottom: '10px'}}
              />

              <label htmlFor='competencies'>Competencies:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='competencies'
                id='competencies'
                placeholder='Enter competencies'
                style={{marginBottom: '10px'}}
              />

              <label htmlFor='accomplishments'>Accomplishments:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='accomplishments'
                id='accomplishments'
                placeholder='Enter accomplishments'
                style={{marginBottom: '20px'}}
              />

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                  type='button'
                  onClick={() => setShowModal(false)}
                  style={{
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </button>
                <button
                  type='submit'
                  style={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
