import {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import {AuthContext} from '../../../hooks/authcontext'
import {Button} from 'react-bootstrap'
import {SortableTable} from '../../components/SortableTable'
import AdminCreateUserModal from '../../components/modals/AdminCreateUserModal'

const AdminUserManagement: FC = () => {
  const intl = useIntl()
  const [openUserModal, setOpenUserModal] = useState(false)
  const {userId} = useContext(AuthContext)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADMIN.USER.MANAGEMENT'})}</PageTitle>

      <div>
        <div className='row g-2 mb-5 g-xl-2' style={{height: '400px'}}>
          <div className='card h-100'>
            <div className='card-header border-0 pt-5 d-flex flex-row'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>User List</span>
                <Button className='w-100' onClick={() => setOpenUserModal(true)}>
                  Add User
                </Button>
              </h3>
            </div>
            <div className='card-body py-3'>
              <SortableTable />
            </div>
          </div>
        </div>
        <AdminCreateUserModal size='lg' openModal={openUserModal} setOpenModal={setOpenUserModal} />
      </div>
    </>
  )
}

export {AdminUserManagement}
