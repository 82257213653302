import React, {createContext, useState, FC, useEffect} from 'react'
import {jwtDecode} from 'jwt-decode'

interface AuthContextType {
  loggedIn: boolean
  userName: string | null
  userId: string | null
  userType: string | null
  login: (name: string, id: string, type: string) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  userName: null,
  userId: null,
  userType: null,
  login: () => {},
  logout: () => {},
})

interface AuthProviderProps {
  children: React.ReactNode
}

interface DecodedToken {
  exp: number
  // Other token payload properties
}

const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [userName, setUserName] = useState<string | null>(null)
  const [userId, setUserId] = useState<string | null>(null)
  const [userType, setUserType] = useState<string | null>(null)

  const login = (name: string | null, id: string | null, type: string | null) => {
    if (id !== null) {
      setLoggedIn(true)
    }
    setUserName(name)
    setUserId(id)
    setUserType(type)
  }

  const logout = () => {
    setLoggedIn(false)
    setUserName(null)
    setUserId(null)
    setUserType(null)
  }

  useEffect(() => {
    async function checkUserInfo() {
      const checkUser = await fetch(
        process.env.REACT_APP_SERVER_NAME + ':' + process.env.REACT_APP_SERVER_PORT + '/api/user',
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
        }
      )
      if (checkUser.status === 200) {
        const content = await checkUser.json()
        login(content['user'], content['userID'], content['type'])
      } else {
        logout()
      }
    }

    checkUserInfo()
  }, [])

  const authContextValue: AuthContextType = {
    loggedIn,
    userName,
    userId,
    userType,
    login,
    logout,
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

export {AuthContext, AuthProvider}
