/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, FC, useContext} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import {Buffer} from 'buffer'
import {
  EmployeeAssessments,
  EmployeeDetails,
  PotentialSkillGroup,
  Skill,
  TrainingCourse,
} from '../../../../dataTypes'
import moment from 'moment'
import {Alert, CardImg, Modal} from 'react-bootstrap'
import {PDFViewer, PDFDownloadLink} from '@react-pdf/renderer'
import ReportPDF from '../../../PDF/ReportPDF'
import {AuthContext} from '../../../../hooks/authcontext'

interface TrainingPathsProps {
  isCompact?: boolean
  employeeQuery?: string
  onSelectSkillNames?: (selectedNames: string[]) => void
  selectedSkillNames?: string[]
  onMinusButtonClick?: (courseIndex: number, courseCategory: string) => void
  minus?: boolean
  callFunc?: string
  potentialEmpty?: boolean
  prevSelectedSkillNamesCont?: string[]
  onPrevSelectSkillNames?: (prevSelectedSkillNames: string[]) => void // Add the onSelectSkillNames prop
}
type Employee = {
  name: string
  totalScore: number
  technicalScore: number
  potentialScore: number
  appraisalScore: number
  lmScore: number
  potentialRatio: number
  technicalRatio: number
  appraisalRatio: number
  lmRatio: number
  'employee id': string
}
interface EmpAppraisalInfo {
  appraisalRatio: string
  appraisalScore: number
  dateModified: string
}

interface TrainingCourseWithCategory extends TrainingCourse {
  'course category': string
}
interface TrainingCourseWithLevel extends TrainingCourseWithCategory {
  'course level': string
}
interface EmpLMInfo {
  managerName: string
  lmScore: number
  dateModified: string
  managerPosition: string
  managerEmpNum: string
}
const ProfileHeader: FC<{employeeQuery?: string; basePath?: string}> = ({
  employeeQuery,
  basePath,
}) => {
  const location = useLocation()
  const [empData, setEmpData] = useState<EmployeeDetails>()
  const [employeeAssessments, setEmployeeAssessments] = useState<EmployeeAssessments[]>([])
  const [completionPercentage, setCompletionPercentage] = useState(0)
  const [scoreEmpData, setScoreEmpData] = useState<Employee>()
  const [age, setAge] = useState<number>(0)
  const [showModal, setShowModal] = useState(false)
  const [showPDFModal, setShowPDFModal] = useState(false)
  const [showDetailedPDFModal, setShowDetailedPDFModal] = useState(false)
  const [imageFeedback, setImageFeedback] = useState('')
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [imageProp, setImageProp] = useState('')
  const [imageBuffer, setImageBuffer] = useState('')
  const [empLMInfo, setEmpLMInfo] = useState<EmpLMInfo>()
  const [updatedLMInfo, setUpdatedLMInfo] = useState<EmpLMInfo>()
  const [updatedData, setUpdatedData] = useState<boolean>(false)
  const [employeeKSA, setEmployeeKSA] = useState<Skill[]>([])
  const [empAppraisalInfo, setEmpAppraisalInfo] = useState<EmpAppraisalInfo>()
  const [employeeSkills, setEmployeeSkills] = useState<PotentialSkillGroup[]>([])

  const [employeeACourseTrainings, setEmployeeACourseTrainings] = useState<TrainingCourse[]>([])
  const [employeeCCourseTrainings, setEmployeeCCourseTrainings] = useState<TrainingCourse[]>([])
  const [employeeFCourseTrainings, setEmployeeFCourseTrainings] = useState<TrainingCourse[]>([])

  const {userId, userType} = useContext(AuthContext)

  async function callEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEmployeeData',
            employee_id: employeeQuery,
          }),
        }
      )
      const allEmpDataCont = await allEmpData.json()
      const employeeData: EmployeeDetails = {
        'employee id': allEmpDataCont.employee['employee id'],
        'employee number': allEmpDataCont.employee['employee number'],
        name: allEmpDataCont.employee.name,
        entity: allEmpDataCont.employee.entity,
        email: allEmpDataCont.employee.email,
        nationality: allEmpDataCont.employee.nationality,
        gender: allEmpDataCont.employee.gender,
        dob: allEmpDataCont.employee.dob,
        'job title': allEmpDataCont.employee['job title'],
        department: allEmpDataCont.employee.department,
        section: allEmpDataCont.employee.section,
        division: allEmpDataCont.employee.division,
        sector: allEmpDataCont.employee.sector,
        'date of joining': allEmpDataCont.employee['date of joining'],
        'years of experience': allEmpDataCont.employee['years of experience'],
        'role reporting to': allEmpDataCont.employee['role reporting to'],
        'reporting to you': allEmpDataCont.employee['reporting to you'],
        'potential score': allEmpDataCont.employee['potential score'],
        'performance technical score': allEmpDataCont.employee['performance technical score'],
        'performance appraisal score': allEmpDataCont.employee['performance appraisal score'],
        'line manager score': allEmpDataCont.employee['line manager score'],
        'employee score': allEmpDataCont.employee['employee score'],
        'talent pool': allEmpDataCont.employee['talent pool'],
        'last login': allEmpDataCont.employee['last login'],
        'function category': allEmpDataCont.employee['function category'],
        'test category': allEmpDataCont.employee['test category'],
        'assessment category': allEmpDataCont.employee['assessment category'],
        'job category': allEmpDataCont.employee['job category'],
        'job level': allEmpDataCont.employee['job level'],
      }

      setEmpData(employeeData)
      callImage(employeeQuery)
    }
  }
  async function callScoreEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'AllEmployeeData',
            employee_id: employeeQuery,
          }),
        }
      )

      const allEmpDataCont = await allEmpData.json()
      const dummyData: Employee = {
        name: allEmpDataCont.employee.name,

        totalScore: allEmpDataCont.employee['employee score'],
        technicalScore: allEmpDataCont.employee['performance technical score'],
        potentialScore: allEmpDataCont.employee['potential score'],
        appraisalScore: allEmpDataCont.employee['performance appraisal score'],
        lmScore: allEmpDataCont.employee['line manager score'],
        'employee id': allEmpDataCont.employee['employee id'],
        potentialRatio: allEmpDataCont['employee ratios']['potential skill ratio'] * 100,
        appraisalRatio: allEmpDataCont['employee ratios']['appraisal skill ratio'] * 100,
        lmRatio: allEmpDataCont['employee ratios']['line manager skill ratio'] * 100,
        technicalRatio: allEmpDataCont['employee ratios']['technical skill ratio'] * 100,
      }

      const totalScoreChartData = [
        {
          name: 'Potential',
          data: [Math.round(dummyData.potentialScore * dummyData.potentialRatio)],
          color: '#0eb48d',
        },
        {
          name: 'Performance (Technical)',
          data: [Math.round(dummyData.technicalScore * dummyData.technicalRatio)],
          color: '#2196F3',
        },
        {
          name: 'Performance (Appraisal)',
          data: [Math.round(dummyData.appraisalScore * dummyData.appraisalRatio)],
          color: '#0eb48d',
        },
        {
          name: 'Line Manager',
          data: [Math.round(dummyData.lmScore * dummyData.lmScore)],
          color: '#2196F3',
        },
      ]

      setScoreEmpData(dummyData)
    }
  }

  async function callAssessments(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const getAssessments = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetEmployeeAssessments',
            employee_id: employeeQuery,
          }),
        }
      )

      const getAssessmentsCont = await getAssessments.json()
      const assessmentsArray: EmployeeAssessments[] = getAssessmentsCont['all assessments'].map(
        (assessment: any) => ({
          'employee assessment id': assessment['employee assessment id'],
          'employee id': assessment['employee id'],
          name: assessment['name'],
          link: assessment['link'],
          'invite id': assessment['invite id'],
          'creation date': assessment['creation date'],
          'expiry date': assessment['expiry date'],
          status: assessment['status'],
        })
      )
      setEmployeeAssessments(assessmentsArray)
      setCompletionPercentage(
        (assessmentsArray.filter((assessment) => assessment.status === 'Complete').length /
          assessmentsArray.length) *
          100
      )
    }
  }
  async function callLineManagerInfo(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empLineManagerInfo = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetLineManagerScore',
            employee_id: employeeQuery,
          }),
        }
      )
      const empLineManagerInfoCont = await empLineManagerInfo.json()
      if (empLineManagerInfoCont['score']) {
        const lmInfo: EmpLMInfo = {
          managerName: empLineManagerInfoCont['manager name'],
          lmScore: empLineManagerInfoCont['score'],
          dateModified: empLineManagerInfoCont['date modified'],
          managerPosition: empLineManagerInfoCont['manager position'],
          managerEmpNum: empLineManagerInfoCont['manager employee number'],
        }

        setEmpLMInfo(lmInfo)
        setUpdatedLMInfo(lmInfo)
        setUpdatedData(false)
      }
    }
  }

  async function callEmpTrainingData(employeeQuery: string | undefined) {
    const empTrainingDData = await fetch(
      process.env.REACT_APP_SERVER_NAME +
        ':' +
        process.env.REACT_APP_SERVER_PORT +
        '/api/employeequery',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEmployeeData',
          employee_id: employeeQuery,
        }),
      }
    )

    const empTrainingDataCont = await empTrainingDData.json()
    const aCourseTrainings: TrainingCourse[] = empTrainingDataCont['acourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course type': course['course type'],
      })
    )

    const cCourseTrainings: TrainingCourse[] = empTrainingDataCont['ccourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course type': course['course type'],
      })
    )

    const fCourseTrainings: TrainingCourse[] = empTrainingDataCont['fcourses']?.map(
      (course: any) => ({
        'course name': course['course name'],
        'course type': course['course type'],
      })
    )

    setEmployeeACourseTrainings(aCourseTrainings || [])
    setEmployeeCCourseTrainings(cCourseTrainings || [])
    setEmployeeFCourseTrainings(fCourseTrainings || [])
  }
  async function callKSAData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empKSA = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EmployeeKSA',
            employee_id: employeeQuery,
          }),
        }
      )

      const empKSACont = await empKSA.json()
      const ksaArray: Skill[] = empKSACont['user ksa'].map((skills: any) => ({
        title: skills['title'],
        score: skills['score'],
        grade: skills['grade'],
        color: skills['color'],
        description: skills['description'],
      }))
      setEmployeeKSA(ksaArray)
    }
  }

  async function callEmpAppraisalInfo(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empAppraisalInfo = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetAppraisalScore',
            employee_id: employeeQuery,
          }),
        }
      )

      const empAppraisalInfoCont = await empAppraisalInfo.json()

      if (empAppraisalInfoCont['appraisal ratio']) {
        const appraisalInfo: EmpAppraisalInfo = {
          appraisalRatio: empAppraisalInfoCont['appraisal ratio'],
          appraisalScore: empAppraisalInfoCont['appraisal score'],
          dateModified: empAppraisalInfoCont['date modified'],
        }

        setEmpAppraisalInfo(appraisalInfo)
      }
    }
  }
  async function callPotentialSkillsData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empPotential = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetPotentialSkills',
            employee_id: employeeQuery,
          }),
        }
      )
      const empPotentialCont = await empPotential.json()
      if (empPotentialCont['potential skills'] !== null) {
        empPotentialCont['potential skills']?.forEach((potentialskill: any) => {
          const potentialSkillGrp: PotentialSkillGroup = {
            group: potentialskill['group'],
            potentialskills: potentialskill['potentialskills'].map((skills: any) => ({
              title: skills['title'],
              score: skills['score'],
              grade: skills['grade'],
              color: skills['color'],
              description: skills['description'],
            })),
          }

          setEmployeeSkills((prevSkills) => {
            // Check if the group already exists in prevSkills
            const existingGroup = prevSkills.find(
              (skill) => skill.group === potentialSkillGrp.group
            )
            if (existingGroup) {
              // Group already exists, return the previous state without modifications
              return prevSkills
            } else {
              // Group is new, add potentialSkillGrp to the previous state
              return [...prevSkills, potentialSkillGrp]
            }
          })
        })
        // setSelectedIndices(Array(empPotentialCont['potential skills'].length).fill(-1))
      } else {
        // setEmployeeSkills([])
      }
    }
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setImageFile(null)
    const formData = new FormData(event.currentTarget)
    if (userId) {
      if ((formData.get('profile-image') as File).size !== 0) {
        setImageFile(formData.get('profile-image') as File)
      }
    }
  }
  useEffect(() => {
    if (imageFile) {
      sendImage()
    }
  }, [imageFile])

  useEffect(() => {
    const interval = setInterval(() => {
      if (imageFeedback == 'Profile Image is required. please try again') setImageFeedback('')
      if (imageFeedback == 'Success') {
        setImageFeedback('')
        setShowModal(false)
        callImage(userId ? userId : undefined)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [imageFeedback])

  async function sendImage() {
    const data = new FormData()
    if (imageFile && userId) {
      data.append('file', imageFile)
      data.append('employee_id', userId)

      const sendImage = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeepicture',
        {
          method: 'POST',
          body: data,
        }
      )
      const sendImgCont = await sendImage.json()

      if (sendImage.status === 200) {
        setImageFeedback('Success')
        // setShowModal(false)
        // callImage(userId)
      }
    } else {
      setImageFeedback('Profile Image is Required. please try again')
    }
  }

  async function callImage(employeeQuery: string | undefined) {
    //check if empty then call default
    if (employeeQuery) {
      const getImg = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetEmployeePicture',
            employee_id: employeeQuery,
          }),
        }
      )

      const getImgCont = await getImg.json()
      if (getImg.status == 200) {
        const certArray = decodeBase64(getImgCont['message'])
        setImageBuffer(getImgCont['message'])
        setImageProp(certArray)
      } else {
        setImageProp('')
      }
    }
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files && event.target.files[0]
    setImageFeedback('')

    if (file) {
      const maxSize = 2 * 1024 * 1024 // 2MB in bytes
      if (file.size <= maxSize) {
        const reader = new FileReader()
        reader.onloadend = function () {
          const result = reader.result
          if (result instanceof ArrayBuffer) {
            const arr = new Uint8Array(result)
            let header = ''
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16)
            }

            if (
              header.startsWith('89504e47') || // PNG
              header.startsWith('ffd8') // JPEG
            ) {
            } else {
              setImageFeedback(
                'Invalid File format. Please select an image file with the extension .jpg or .png'
              )
            }
          } else {
            setImageFeedback(
              'Invalid File format. Please select an image file with the extension .jpg or .png'
            )
          }
        }

        // Read the file as an array buffer
        reader.readAsArrayBuffer(file)
      } else {
        // File size exceeds the limit
        setImageFeedback('The file is too large. Please select a smaller image')
      }
    }
  }

  function decodeBase64(data: any) {
    const decodedData = Buffer.from(data, 'base64')
    const blob = new Blob([decodedData], {type: 'image/jpeg'})
    const imageUrl = URL.createObjectURL(blob)
    return imageUrl
  }

  const todayDate = new Date()
  useEffect(() => {
    if (employeeQuery) {
      //these would only get called if its being called by an entity through their search function
      callEmpData(employeeQuery)
      // callImage(employeeQuery)
      callEmpTrainingData(employeeQuery)
      callScoreEmpData(employeeQuery)
      callAssessments(employeeQuery)
      callLineManagerInfo(employeeQuery)
      callPotentialSkillsData(employeeQuery)
      callEmpAppraisalInfo(employeeQuery)
      callKSAData(employeeQuery)
      // console.log('got employee query in header', employeeQuery)
    } else {
      callEmpData(userId ? userId : undefined)
    }
  }, [employeeQuery])

  useEffect(() => {
    if (empData !== undefined) {
      const parsedDate = moment(empData['dob'], 'DD/MM/YYYY')
      if (parsedDate.isValid()) {
        const calculatedAge = moment().diff(parsedDate, 'years')
        setAge(calculatedAge)
      } else {
        setAge(0)
      }
    }
  }, [empData])

  function getBackgroundColor(grade: number) {
    if (grade >= 76) {
      return 'bg-proficient-grade'
    } else if (grade >= 51) {
      return 'bg-experienced-grade'
    } else if (grade >= 26) {
      return 'bg-intermediate-grade'
    } else {
      return 'bg-beginner-grade'
    }
  }
  var talentPoolSet = empData?.['talent pool'] === 'true'
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {empData && (
          <div className='card-body pt-9 pb-0 d-flex flex-row'>
            <div className='col-xl-10'>
              {employeeQuery && <div className='col-12 mb-4'></div>}
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={
                        imageProp !== ''
                          ? toAbsoluteUrl(imageProp)
                          : toAbsoluteUrl('/media/avatars/blank.png')
                      }
                      onClick={userType == 'employee' ? () => setShowModal(true) : () => {}}
                      style={{cursor: 'pointer'}}
                      alt='Metornic'
                    />
                  </div>
                </div>

                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          {empData.name}
                          {talentPoolSet === true && (
                            <i className=' px-2 fa-regular fa-circle-check text-primary fs-2'></i>
                          )}
                        </a>
                        <a href='#'></a>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='geolocation-home' className='fs-4 me-1' />
                          {empData['nationality']}
                        </a>

                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {empData['email']}
                        </a>

                        {age !== 0 && (
                          <a
                            href='#'
                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                          >
                            <KTIcon iconName='calendar-2' className='fs-4 me-1' />
                            {age} yrs
                          </a>
                        )}

                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary  mb-2'
                        >
                          <KTIcon iconName='user' className='fs-4 me-1' />
                          {empData['gender']}
                        </a>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'></div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div
                          className={`border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ${getBackgroundColor(
                            Number(empData['employee score']) * 100
                          )}`}
                          style={{
                            backgroundColor: getBackgroundColor(
                              Number(empData['employee score']) * 100
                            ),
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder text-white'>
                              {Math.round(Number(empData['employee score']) * 100)}%
                            </div>
                          </div>
                          <div className='fw-bold fs-6 text-white'>Overall Grade</div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>{empData.entity}</div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Entity</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>{empData['job title']}</div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Job Title</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>{empData['role reporting to']}</div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Reports to</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>{empData['reporting to you']}</div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Reportees</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!employeeQuery && (
                <div className='d-flex overflow-auto h-55px'>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/overview' && 'active')
                        }
                        to='/profile/overview'
                      >
                        Overview
                      </Link>
                    </li>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/education' && 'active')
                        }
                        to='/profile/education'
                      >
                        Education
                      </Link>
                    </li>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/certification' && 'active')
                        }
                        to='/profile/certification'
                      >
                        Certification
                      </Link>
                    </li>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/performance' && 'active')
                        }
                        to='/profile/performance'
                      >
                        Performance Result
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/potential' && 'active')
                        }
                        to='/profile/potential'
                      >
                        Potential Result
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/assessments' && 'active')
                        }
                        to='/profile/assessments'
                      >
                        Assigned Assessments
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === '/profile/training-paths' && 'active')
                        }
                        to='/profile/training-paths'
                      >
                        Training Path
                      </Link>
                    </li>
                    <li className='nav-item'></li>
                  </ul>
                </div>
              )}
            </div>
            {userType !== 'employee' && (
              <div className='col-xl-2 d-flex flex-column align-items-center justify-content-center gap-4'>
                <div
                  className='btn btn-primary'
                  onClick={userType !== 'employee' ? () => setShowPDFModal(true) : () => {}}
                >
                  <span className='card-label me-2'>View Compact PDF</span>
                  <i className='ml-2 fa-solid fa-print'></i>
                </div>
                <div
                  className='btn btn-primary'
                  onClick={userType !== 'employee' ? () => setShowDetailedPDFModal(true) : () => {}}
                >
                  <span className='card-label me-2'>View Detailed PDF</span>
                  <i className='ml-2 fa-solid fa-print'></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <input
                type='file'
                className='form-control form-control-lg form-control-solid'
                name='profile-image'
                id='profile-image'
                accept='image/png, image/jpeg, image/jpg'
                onChange={handleFileChange}
              />
              <label htmlFor='profile-image' style={{marginBottom: '10px'}}>
                (png, jpg, jpeg files accepted. Max file size: 2MB)
              </label>
              {imageFeedback !== '' && imageFeedback !== 'Success' && (
                <Alert variant='danger' style={{marginBottom: '20px'}}>
                  {imageFeedback}
                </Alert>
              )}
              {imageFeedback == 'Success' && (
                <Alert variant='success' style={{marginBottom: '20px'}}>
                  {imageFeedback}
                </Alert>
              )}

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                  type='button'
                  onClick={() => setShowModal(false)}
                  style={{
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </button>
                <button
                  type='submit'
                  disabled={imageFeedback == '' ? false : true}
                  style={{
                    backgroundColor: `${imageFeedback == '' ? '#4CAF50' : '#bddebe'}`,
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: `${imageFeedback == '' ? 'pointer' : 'auto'}`,
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showPDFModal} size='lg' onHide={() => setShowPDFModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Export Compact Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              flexGrow: 1,
              height: '450px',
            }}
          >
            <PDFViewer
              showToolbar={false}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <ReportPDF
                concise={true}
                empData={empData}
                empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                aCourseTraining={employeeACourseTrainings}
                cCourseTraining={employeeCCourseTrainings}
                fCourseTraining={employeeFCourseTrainings}
                scoreEmpData={scoreEmpData}
                age={age}
                assessments={employeeAssessments}
                lineManager={empLMInfo}
                empKSA={employeeKSA}
                potentialSkills={employeeSkills}
                appraisalInfo={empAppraisalInfo}
                completionPerc={completionPercentage}
              />
            </PDFViewer>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PDFDownloadLink
            document={
              <ReportPDF
                concise={true}
                empData={empData}
                empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                aCourseTraining={employeeACourseTrainings}
                cCourseTraining={employeeCCourseTrainings}
                fCourseTraining={employeeFCourseTrainings}
                scoreEmpData={scoreEmpData}
                age={age}
                assessments={employeeAssessments}
                lineManager={empLMInfo}
                empKSA={employeeKSA}
                potentialSkills={employeeSkills}
                appraisalInfo={empAppraisalInfo}
                completionPerc={completionPercentage}
              />
            }
            fileName={`HPE TES-${empData?.name}-Compact-${todayDate.toLocaleDateString(
              'en-GB'
            )}.pdf`}
          >
            {/* <div className='btn btn-primary '>
              Download
              <i className='px-2 fa-solid fa-print'></i>
            </div> */}
            {({loading}) =>
              loading ? (
                <div className=' h-100 pt-2 pb-4 d-flex justify-content-center'>
                  <span
                    className='spinner-border spinner-border-lg spinner-info'
                    style={{
                      width: '40px',
                      height: '40px',
                      border: 'var(--bs-spinner-border-width) solid #01a982',
                      borderRightColor: 'var(--bs-light)',
                      borderWidth: '5px',
                    }}
                  ></span>
                </div>
              ) : (
                <div className='btn btn-primary '>
                  Download
                  <i className='px-2 fa-solid fa-print'></i>
                </div>
              )
            }
          </PDFDownloadLink>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDetailedPDFModal}
        size='lg'
        onHide={() => {
          setShowDetailedPDFModal(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Detailed Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              flexGrow: 1,
              height: '450px',
            }}
          >
            <PDFViewer
              showToolbar={false}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <ReportPDF
                empData={empData}
                empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                aCourseTraining={employeeACourseTrainings}
                cCourseTraining={employeeCCourseTrainings}
                fCourseTraining={employeeFCourseTrainings}
                scoreEmpData={scoreEmpData}
                age={age}
                assessments={employeeAssessments}
                lineManager={empLMInfo}
                empKSA={employeeKSA}
                potentialSkills={employeeSkills}
                appraisalInfo={empAppraisalInfo}
                completionPerc={completionPercentage}
              />
            </PDFViewer>
          </div>

          {/* <div
            style={{
              flexGrow: 1,
              height: '450px',
            }}
          >
            {showLoadingPdf ? (
              <PDFViewer
                showToolbar={false}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <ReportPDF
                  empData={empData}
                  empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                  aCourseTraining={employeeACourseTrainings}
                  cCourseTraining={employeeCCourseTrainings}
                  fCourseTraining={employeeFCourseTrainings}
                  scoreEmpData={scoreEmpData}
                  age={age}
                  assessments={employeeAssessments}
                  lineManager={empLMInfo}
                  empKSA={employeeKSA}
                  potentialSkills={employeeSkills}
                  appraisalInfo={empAppraisalInfo}
                  completionPerc={completionPercentage}
                />
              </PDFViewer>
            ) : (
              <div className=' h-100 pt-2 pb-4 d-flex justify-content-center'>
                <span
                  className='spinner-border spinner-border-lg spinner-info'
                  style={{
                    width: '80px',
                    height: '80px',
                    border: 'var(--bs-spinner-border-width) solid #01a982',
                    borderRightColor: 'var(--bs-light)',
                    borderWidth: '10px',
                  }}
                ></span>
              </div>
            )}
          </div> */}

          {/* {showLoadingPdf ? (
            <div
              style={{
                flexGrow: 1,
                height: '450px',
              }}
            >
              <PDFViewer
                showToolbar={false}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <ReportPDF
                  empData={empData}
                  empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                  aCourseTraining={employeeACourseTrainings}
                  cCourseTraining={employeeCCourseTrainings}
                  fCourseTraining={employeeFCourseTrainings}
                  scoreEmpData={scoreEmpData}
                  age={age}
                  assessments={employeeAssessments}
                  lineManager={empLMInfo}
                  empKSA={employeeKSA}
                  potentialSkills={employeeSkills}
                  appraisalInfo={empAppraisalInfo}
                  completionPerc={completionPercentage}
                />
              </PDFViewer>
            </div>
          ) : (
            <div className=' h-100 pt-2 pb-4 d-flex justify-content-center'>
              <span
                className='spinner-border spinner-border-lg spinner-info'
                style={{
                  width: '80px',
                  height: '80px',
                  border: 'var(--bs-spinner-border-width) solid #01a982',
                  borderRightColor: 'var(--bs-light)',
                  borderWidth: '10px',
                }}
              ></span>
            </div>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <PDFDownloadLink
            document={
              <ReportPDF
                // concise={true}
                empData={empData}
                empPfp={imageProp !== '' ? imageProp : toAbsoluteUrl('/media/avatars/blank.png')}
                aCourseTraining={employeeACourseTrainings}
                cCourseTraining={employeeCCourseTrainings}
                fCourseTraining={employeeFCourseTrainings}
                scoreEmpData={scoreEmpData}
                age={age}
                assessments={employeeAssessments}
                lineManager={empLMInfo}
                empKSA={employeeKSA}
                potentialSkills={employeeSkills}
                appraisalInfo={empAppraisalInfo}
                completionPerc={completionPercentage}
              />
            }
            fileName={`HPE TES-${empData?.name}-Detailed-${todayDate.toLocaleDateString(
              'en-GB'
            )}.pdf`}
          >
            {({loading}) =>
              loading ? (
                <div className=' h-100 pt-2 pb-4 d-flex justify-content-center'>
                  <span
                    className='spinner-border spinner-border-lg spinner-info'
                    style={{
                      width: '40px',
                      height: '40px',
                      border: 'var(--bs-spinner-border-width) solid #01a982',
                      borderRightColor: 'var(--bs-light)',
                      borderWidth: '5px',
                    }}
                  ></span>
                </div>
              ) : (
                <div className='btn btn-primary '>
                  Download
                  <i className='px-2 fa-solid fa-print'></i>
                </div>
              )
            }
            {/* <div className='btn btn-primary '>
              Download
              <i className='px-2 fa-solid fa-print'></i>
            </div> */}
          </PDFDownloadLink>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ProfileHeader}
