/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {Link} from 'react-router-dom'
import {useCallInterval} from '../../../../hooks/CallTimeIntervalContext'
import {AuthContext} from '../../../../hooks/authcontext'

type Props = {
  className: string
  title?: string
}

const DepartmentsTable: React.FC<Props> = ({className, title}) => {
  const [entityDepartmentInfo, setEntityDepartmentInfo] = useState<string[][]>()

  const {userId} = useContext(AuthContext)
  const {callInterval} = useCallInterval()

  async function callEntDepartments() {
    if (userId) {
      const entDepartment = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityDepartmentInformation',
            entity_id: userId,
          }),
        }
      )
      const entDepartmentCont = await entDepartment.json()
      setEntityDepartmentInfo(entDepartmentCont['department information'])
    }
  }

  useEffect(() => {
    callEntDepartments()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntDepartments()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  const handleRefresh = () => {
    callEntDepartments()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 flex-row'>
        <h3 className='card-title align-items-start me-auto '>
          <Link to={'/candidate-overview?department'}>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </Link>
        </h3>
      </div>

      <div className='card-body py-3' style={{maxHeight: '500px', overflow: 'auto'}}>
        {entityDepartmentInfo ? (
          <TableWithScore
            tableData={entityDepartmentInfo}
            link='/candidate-overview?department='
            canRedirect={true}
          />
        ) : (
          <WarningSpinner canReload={true} reloadFunc={callEntDepartments} spinnerWidth='60px' />
        )}
      </div>
    </div>
  )
}

export {DepartmentsTable}
