/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {EmployeeAssessments} from '../../../../dataTypes'

type Props = {
  className: string
  employeeAssessments: EmployeeAssessments[]
  completionPercentage?: number
  isCompact?: boolean
  employeeQuery?: string
}

const AssesmentsTable: React.FC<Props> = ({
  className,
  employeeAssessments,
  completionPercentage,
  isCompact = false,
  employeeQuery,
}) => {
  const location = useLocation()
  const [selectedLink, setSelectedLink] = useState('')
  const threshold = 2

  const handleLinkClick = (e: any) => {
    e.preventDefault()
    if (e.target.href) {
      setSelectedLink(e.target.href)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {isCompact && employeeQuery == '' ? (
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/profile/Assessments' && 'active')
              }
              to='/profile/Assessments'
            >
              <span className='card-label fw-bold fs-3 mb-1'>Assessment Status</span>
            </Link>
          ) : (
            <span className='card-label fw-bold fs-3 mb-1'>Assessment Status</span>
          )}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={{overflow: 'scroll'}}>
        <div className='py-3 px-5'>
          <span className='card-label fw-bolder text-gray-800 fs-6 mb-1 mr-1'>
            Assessments Completed:{' '}
          </span>
          <span className='fw-bolder text-gray-800 fs-6 flex-end'>
            {completionPercentage?.toFixed(0)}%
          </span>
        </div>

        <div className='h-8px mb-3 px-5 py-0'>
          <div className='progress-bar w-100 bg-light h-8px  rounded ' role='progressbar'>
            <div
              className=' progress bg-success rounded h-8px'
              style={{
                width:
                  completionPercentage &&
                  `${completionPercentage <= threshold ? threshold : completionPercentage}%`,
              }}
            ></div>
          </div>
        </div>

        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table align-middle gs-5 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th className='min-w-100px'>Assesment</th>
                <th className='min-w-100px '>Deadline</th>
                {!isCompact && (
                  <>
                    <th className='min-w-120px '>Notes</th>
                    <th className='min-w-120px '>Link</th>
                  </>
                )}

                <th className='min-w-100px '>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {employeeAssessments.map((assessment) => (
                <tr key={assessment['employee assessment id']}>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
                    {assessment.name}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
                    {new Date(assessment['expiry date']).toLocaleDateString()}
                  </td>
                  {!isCompact && (
                    <>
                      <td className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
                        {assessment['invite id']}
                      </td>
                      <td>
                        {' '}
                        {assessment.status === 'Pending' ? (
                          <a
                            href={assessment.link}
                            // onClick={handleLinkClick}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-success'
                          >
                            Launch Test
                          </a>
                        ) : (
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-7'>-</span>
                        )}
                      </td>
                    </>
                  )}

                  <td>
                    {assessment.status === 'Pending' ? (
                      <a className='badge badge-warning fs-7 fw-bold'>Pending</a>
                    ) : (
                      <span className='badge badge-light-success fs-7 fw-bold'>Completed</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>

        {selectedLink !== '' && (
          <div>
            <span className='card-label fw-bold fs-3 mb-1'>Assessment</span>
            <iframe className='w-100' style={{height: '600px'}} src={selectedLink}></iframe>
          </div>
        )}
      </div>
    </div>
  )
}

export {AssesmentsTable}
