/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {useCallInterval} from '../../../../hooks/CallTimeIntervalContext'
import {AuthContext} from '../../../../hooks/authcontext'

type Props = {
  className: string
  title?: string
}

const EntAssessmentInfoTable: React.FC<Props> = ({className, title}) => {
  const [entityGradeInfo, setEntityGradeInfo] = useState<string[][]>()
  const {userId} = useContext(AuthContext)
  const {callInterval} = useCallInterval()

  async function callEntGradeInfo() {
    if (userId) {
      const gradeInfo = await fetch(
        process.env.REACT_APP_SERVER_NAME +
          ':' +
          process.env.REACT_APP_SERVER_PORT +
          '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityOverallAssessmentInfo',
            entity_id: userId,
          }),
        }
      )
      const gradeInfoCont = await gradeInfo.json()

      setEntityGradeInfo(gradeInfoCont['overall assessment information'])
    }
  }

  useEffect(() => {
    callEntGradeInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntGradeInfo()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  const handleRefresh = () => {
    callEntGradeInfo()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header flex-row border-0 pt-5'>
        <h3 className='card-title align-items-start me-auto'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body py-3' style={{maxHeight: '500px', overflow: 'auto'}}>
        {entityGradeInfo ? (
          <TableWithScore tableData={entityGradeInfo} />
        ) : (
          <WarningSpinner spinnerWidth='100px' />
        )}
      </div>
    </div>
  )
}

export {EntAssessmentInfoTable}
