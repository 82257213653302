import {lazy, FC, Suspense, useEffect, useContext} from 'react'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  useNavigate,
} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Login} from '../pages/Login'
import {EmployeeProfile} from '../modules/profile/entity_profile/EmployeeProfile_Ent'
import {EmployeeCompetencies} from '../modules/profile/entity_profile/EmployeeCompetencies_Ent'
import ProfilePageTestPaths_Ent from '../modules/profile/entity_profile/ProfilePageTraining_Ent'
import {EmployeeTraining} from '../modules/profile/entity_profile/EmployeeTraining_Ent'
import {AuthContext} from '../../hooks/authcontext'
import {Nav} from 'react-bootstrap'
import {AdminUserManagement} from '../pages/admin/AdminUserManagement'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/client_profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const {userId, loggedIn} = useContext(AuthContext)
  const navigate = useNavigate()

  // const location = useLocation()
  // const navigationType = useNavigationType() // "POP" | "PUSH" | "REPLACE"

  // useEffect(() => {
  //   console.log('The current URL is', {...location})
  //   console.log('The last navigation action was', navigationType)
  // }, [location, navigationType])

  useEffect(() => {
    console.log('Login Status Changed to:' + loggedIn)
    if (loggedIn) {
      navigate('/dashboard')
    } else {
      navigate('/login')
    }
  }, [loggedIn])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route
          path='/'
          element={loggedIn ? <Navigate to='/dashboard' /> : <Navigate to='/login' />}
        />
        <Route
          path='/dashboard'
          element={
            loggedIn ? (
              <SuspensedView>
                <DashboardWrapper />
              </SuspensedView>
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/login'
          element={
            <SuspensedView>
              <Login />
            </SuspensedView>
          }
        />
        <Route
          path='/*'
          element={
            loggedIn ? (
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/user-management'
          element={
            <SuspensedView>
              <AdminUserManagement />
            </SuspensedView>
          }
        />

        <Route
          path='profile/*'
          element={
            loggedIn ? (
              <SuspensedView>{userId !== '' && <ProfilePage />}</SuspensedView>
            ) : (
              <Navigate to='/login' />
            )
          }
        />

        <Route
          path='/candidate-profile/*'
          element={
            <SuspensedView>
              <EmployeeProfile />
            </SuspensedView>
          }
        />
        <Route
          path='/candidate-competencies'
          element={
            <SuspensedView>
              <EmployeeCompetencies />
            </SuspensedView>
          }
        />
        <Route
          path='/candidate-training/*'
          element={
            <SuspensedView>
              <EmployeeTraining />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
